import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { MerchantService } from "../api";

import logo from "../assets/images/eamr-logo.PNG";
import "../assets/auth.css";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "",
      mailing_address: "",
      email: "",
      reg_no: "",
      phone: "",
      dob: "",
      description: "",
      gender: "",
      password: "",
      confirmpassword: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.password !== this.state.confirmpassword) {
      Swal.fire("Error", "Password and confirm password do not match", "error");
    } else {
      const data = {
        name: this.state.name,
        phone: this.state.phone,
        password: this.state.password,
        reg_no: this.state.reg_no,
        description: this.state.description,
        address: this.state.address,
        email: this.state.email,
        dob: this.state.dob,
        gender: this.state.gender,
        mailing_address: this.state.mailing_address,
      };
      MerchantService.signup(data)
        .then((res) => {
          if (res.success) {
            Swal.fire("Success", res.message, "success");
            setTimeout(() => {
              window.location = "/products";
            }, 2000);
          } else {
            Swal.fire("Sorry", res.message, "error");
          }
        })
        .catch((err) => {
          Swal.fire("Sorry", err.message, "error");
        });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div
        className="text-center auth-container"
        style={{ backgroundColor: "#007bff", color: "#fff", height: "100%" }}
      >
        <img src={logo} alt="EAMR Logo" width={100} />
        <h3>EAMR</h3>
        <h3>Sign Up</h3>
        <div>
          <form className="auth-form" onSubmit={this.handleSubmit}>
            <div className="form-row form-group">
              <div className="col">
                <label htmlFor="">Company Name</label>
                <input
                  type="text"
                  placeholder="Company Name"
                  name="name"
                  className="auth-input"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col">
                <label htmlFor="">Address</label>
                <input
                  type="text"
                  placeholder="Address"
                  name="address"
                  className="auth-input"
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="form-row form-group">
              <div className="col">
                <label htmlFor="">Email*</label>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  className="auth-input"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col">
                <label htmlFor="">Company Reg No.*</label>
                <input
                  type="text"
                  placeholder="Registration Number"
                  name="reg_no"
                  className="auth-input"
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="form-row form-group">
              <div className="col">
                <label htmlFor="">Phone Number*</label>
                <input
                  type="tel"
                  placeholder="Phone Number"
                  name="phone"
                  className="auth-input"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col">
                <label htmlFor="">Mailing Address</label>
                <input
                  type="text"
                  placeholder="Mailing Address"
                  name="mailing_address"
                  className="auth-input"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="form-group form-row">
              <div className="col">
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  className="auth-input"
                  name="password"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col">
                <label htmlFor="">Confirm Password</label>
                <input
                  type="password"
                  className="auth-input"
                  name="confirmpassword"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div>
              <div className="form-row form-group">
                <div className="col">
                  <label htmlFor="">Date of Birth</label>
                  <input
                    type="date"
                    className="auth-input"
                    name="dob"
                    onChange={this.handleChange}
                  />
                  <p>
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      onChange={this.handleChange}
                    />
                    Male
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      onChange={this.handleChange}
                    />
                    Female
                  </p>
                </div>
                <div className="col">
                  <label htmlFor="">Company Description</label>
                  <textarea
                    id=""
                    cols="10"
                    rows="5"
                    className="auth-input"
                    name="description"
                    onChange={this.handleChange}
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary deep-btn"
                onClick={this.handleSubmit}
              >
                Sign Up
              </button>
            </div>
          </form>
          <div>
            <Link className="btn btn-outline-light" to="/login">
              Back to log in
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
