import React, { Component } from "react";
import { Link } from "react-router-dom";
import Notify from "cogo-toast";
import Chat from "./Chat";
import io from "socket.io-client";
import { Chat as ChatApi } from "../../api/index";
import { FiSend } from "react-icons/fi";

import productImg from "../../assets/images/product-img1.png";
import starIcon from "../../assets/images/star-yellow-ico.svg";
import avatarIcon from "../../assets/images/avatar.png";
import PageLayout from "../layouts/pageLayouts";
import { obj } from "../../constants";
import { IoSendSharp } from "react-icons/io5";
import { BsChatRightDots, BsChatSquareText } from "react-icons/bs";
import { Avatar, Image, Space } from "antd";
import "./styles.css";

const endpoint = obj.serverurl;

let socket;

class ChatLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      showChat: false,
      chatgroups: [],
      userid: "",
      merchantid: "",
      room: {},
      textmessage: "",
      lastMessages: [],
      orderID: "",
    };
  }

  componentDidMount() {
    socket = io(endpoint);

    const merchantid = sessionStorage.getItem("merchantid");

    if (!merchantid) {
      window.location.href = "/login";
    } else {
      ChatApi.getMerchantGroups({ id: merchantid })
        .then((res) => {
          console.log("Chatgroupss>>>", res.groups)
          this.setState({ chatgroups: res.groups });
          console.log("merchnat groups", res.groups);

          const orderID = window.location.search.split("=")[1];
          if (orderID) {
            console.log("chat detailsss?>>", sessionStorage.getItem("chatdetail"));

            const chatdetail = sessionStorage.getItem("chatdetail").split("_"); //orderid_useremail_productID
            const commongroup = this.state.chatgroups.filter(
              (group) =>
                group.profile_id == chatdetail[1] &&
                group.product_id == chatdetail[2]
            );
            console.log("Common group>>", commongroup)
            if (commongroup.length === 0) {
              ChatApi.createGroup({
                userid: chatdetail[1],
                merchantid: merchantid,
                productid: chatdetail[2],
              })
                .then((res) => {
                  //Update Groups UI
                  ChatApi.getMerchantGroups({ id: merchantid }).then((res) => {
                    console.log("merchnat groups now>>", res);
                    this.setState({ chatgroups: res.groups });
                  });
                  Notify.success("New room joined");
                  console.log(res);
                })
                .catch((err) => {
                  console.log("Create group error",err);
                });
            }
          } else {
          }
        })
        .catch((err) => {
          Notify.error("An error occurred");
          console.log(err);
        });
    }

    socket.on("message", (message) => {
      // console.log("New message!",message)
      this.setState({ messages: [...this.state.messages, message.chat] });
    });

    // socket.on("room-data", (users) => {
    //     console.log(users)
    //     this.setState({ users })
    // });

    ChatApi.getLastChats({ id: merchantid })
      .then((res) => {
        this.setState({ lastMessages: res.messages });
      })
      .then((err) => {
        console.log(err);
      });
  }

  displayChat = (room) => {
    const name = "arib";
    console.log("ROom>>", room)
    // const room = "merchant1";
    this.setState({ room });
    socket.emit("join", { name, room: room.id });
    const data = {
      merchantid: room.exhibitor_id,
      userid: room.profile_id,
      productid: room.product_id,
    };
    ChatApi.getProductChats(data)
      .then((res) => {
        this.setState({ messages: res.chats });
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ showChat: true });
  };

  handleSubmit = () => {
    console.log(this.state.textmessage);
    if (this.state.textmessage.trim().length > 0) {
      const message = {
        userid: this.state.room.profile_id,
        merchantid: this.state.room.exhibitor_id,
        message: this.state.textmessage,
        adminMessage: true,
        product_id: this.state.room.product_id,
        room: this.state.room.id,
        created: new Date(),
      };
      socket.emit("send-message", message);
      this.setState({ textmessage: "" });
    }
  };

  render() {
    return (
      <>
        <PageLayout>
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <h4 className="text-left">Chat</h4>
            </div>
          </div>
          <div className="col-12">
            <div className="">
              <section className="chats mb-4">
                {this.state.chatgroups ? (
                  this.state.chatgroups.map((group) => {
                    const lastMsg = this.state.lastMessages
                      ? this.state.lastMessages.filter(
                          (msg) => msg.product_id == group.product_id
                        )
                      : [];
                    return (
                      <Chat
                        avatar={avatarIcon}
                        key={group.id}
                        displayChat={() => this.displayChat(group)}
                        group={group}
                        lastMsg={lastMsg[0]}
                      />
                    );
                  })
                ) : (
                  <p>No chats has been initiated yet.</p>
                )}
                {/* <Chat avatar={avatarIcon} />
                            <Chat avatar={avatarIcon} />
                            <Chat avatar={avatarIcon} />
                            <Chat avatar={avatarIcon} /> */}
              </section>
              {this.state.showChat ? (
                <section className="border rounded chat__view">
                  <div className="d-flex border-bottom row align-items-center chat__view-head">
                    <img
                      src={avatarIcon}
                      alt=""
                      className="review-avatar rounded-circle ml-4"
                    />
                    <span className="font-weight-bold">
                      {this.state.room.name}
                    </span>
                  </div>
                  <div className="chat__view-body">
                    <div className="chat__view-product d-flex border rounded col-8 align-items-center">
                      <Link to={"/products/" + this.state.room.product_id}>
                        <img
                          src={
                            this.state.room
                              ? this.state.room.photo1
                              : productImg
                          }
                          alt=""
                          className="review-avatar"
                        />
                      </Link>
                      <div className="">
                        <p className="font-weight-bold">
                          {this.state.room.product_name}
                        </p>
                        <p className="small">
                          {this.state.room
                            ? this.state.room.description.slice(0, 50)
                            : null}
                          ...
                        </p>
                        <p className="small text-muted">
                          ${this.state.room.price}
                        </p>
                      </div>
                    </div>

                    <div className="chat__list">
                      {this.state.messages && this.state.messages.length > 0 ? (
                        this.state.messages.map((msg, index) => {
                          return msg ? (
                            <div
                              key={index}
                              className={
                                "chat__box " +
                                (msg.admin_msg == "Y" ? "sender" : "receiver") +
                                " d-flex align-items-end col-10 "
                              }
                            >
                              <div className={"message small"}>{msg.message}</div>
                              <span className={"text-muted small " + (msg.admin_msg == "Y" ? "" : "ml-5")}>
                                {new Date(msg.created_date).toDateString()}/
                                {new Date(msg.created_date).getHours()}.
                                {new Date(msg.created_date).getMinutes()}
                              </span>
                            </div>
                          ) : null;
                        })
                      ) : (
                        <p>No discussion has been made.</p>
                      )}
                      {/* <div className="chat__box receiver d-flex align-items-end col-10">
                                        <div className="message small">Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi, mollitia!</div>
                                        <span className="text-muted small">10.00am</span>
                                    </div> */}
                    </div>
                  </div>
                  <div className="chat-footer">
            
                      <div className="d-flex justify-content-md-between align-content-center __wrap_c">
                        <input
                          type="text"
                          value={this.state.textmessage}
                          onChange={(e) =>
                            this.setState({
                              textmessage: e.target.value,
                            })
                          }
                          className="__chatinput w-full"
                        />
                        <button
                          className="__chatbtn"
                          onClick={this.handleSubmit}
                        >
                          <span className="pr-2 d-sm-none">Send</span>
                          <IoSendSharp />
                        </button>
                      </div>
               
                  </div>
                </section>
              ) : null}
            </div>
          </div>
        </PageLayout>
      </>
    );
  }
}

export default ChatLists;
