import { Link } from "react-router-dom";
import { Empty, Typography } from "antd";
import { PrimaryBtn } from "../button/Button";
import empty from "../../assets/images/empty.svg";

const { Title } = Typography;

const EmptyResult = ({ height, desc, action, to }) => {
  return (
    <Empty
      image={empty}
      imageStyle={{
        height: height,
      }}
      description={
        <Title level={5} className="my-4">
          {desc}
        </Title>
      }
    >
      {to ? (
        <Link to={to}>
          <PrimaryBtn text={action} />
        </Link>
      ) : (
        <></>
      )}
    </Empty>
  );
};

export default EmptyResult;
