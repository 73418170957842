import Layout from "./Layout";

export default function PageLayout({ children, className, headerInputValue, handleHeaderInputChange }) {
  return (
    <>
      <Layout headerInputValue={headerInputValue} handleHeaderInputChange={handleHeaderInputChange}>
        <main>
            {children}
        </main>
      </Layout>
    </>
  );
}
