import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import leftArrowIcon from "../../assets/images/arrow-left.svg";
import Layout from "../layouts/pageLayouts";
import { InputLabel, SelectLabel, TextArea } from "../inputFields/Input";
import { PrimaryBtn } from "../button/Button";
import { MerchantService } from "../../api";

// let socket;

export default function EditProfile() {
  const [form, setForm] = useState({});
  const [userId, setUserId] = useState("");
  // const [merchant, setMerchant] = useState({});

  const handleChange = (e) => {
    e.preventDefault();
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const id = sessionStorage.getItem("merchantid");
    if (!id) {
      return (window.location.href = "/profile");
    } else {
      setUserId(id);
      MerchantService.fetchdetails(id).then((res) => {
        setForm(res.data);
      });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    MerchantService.updatemerchant({ ...form, merchant_id: userId })
      .then((res) => {
        if (res.success) {
          Swal.fire("Success", "Changes successfully updated!", "success");
          setTimeout(() => {
            window.location = "/profile";
          }, 2000);
        } else {
          Swal.fire("Sorry", "Please fill in valid details", "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <Layout>
      <div className="col-12">
        <h4 className="d-flex align-items-center">
          <Link to="/profile" className="mr-3">
            <img src={leftArrowIcon} alt="Go back" />
          </Link>
          <span className="__title">Edit Profile</span>
        </h4>
      </div>
      <div className="product__form-container col-12">
        <form className="product__form" onSubmit={handleSubmit}>
          <div className="form-group pb-2">
            <InputLabel
              label="Company Name"
              name="company_name"
              value={form.company_name}
              onChange={handleChange}
            />
          </div>
          <div className="form-row pb-2">
            <div className="form-group col-md-6">
              <InputLabel
                type="tel"
                label="Phone Number"
                name="phone_number"
                value={form.phone_number}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <InputLabel
                type="date"
                label="Date of Birth"
                name="dob"
                value={form.dob}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row pb-2">
            <div className="form-group col-md-6">
              <InputLabel
                label="Address"
                name="address"
                value={form.address}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <InputLabel
                label="Mailing Address"
                name="mailing_address"
                value={form.mailing_address}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row pb-2">
            <div className="form-group col-md-6">
              <InputLabel
                label="Company Reg No#"
                name="company_reg_no"
                value={form.company_reg_no}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <SelectLabel
                label="Gender"
                choices={["Male", "Female"]}
                name="gender"
                defaultValue={form.gender}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-12">
              <TextArea
                label="Company Description"
                name="description"
                rows="5"
                value={form.description}
                onChange={handleChange}
                // placeholder="`Describe your product`"
              />
            </div>
          </div>
          <PrimaryBtn block text="Save Changes" onClick={handleSubmit} />
        </form>
      </div>
    </Layout>
  );
}
