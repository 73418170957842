export const formatOrderDate = (dateToFormat) => {
  const date = new Date(dateToFormat);
  const dayOfMonth = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${formatDate(dayOfMonth)}/${formatDate(month)}/${formatDate(year)}`;
};

const formatDate = (date) => {
  return date < 10 ? "0" + date : date;
};

// export const expandOrders = (orders) => {
//   const expandedOrders = [];
//   for (let i = 0; i < orders.length; i++) {
//     const order = orders[i];
//     for (let j = 0; j < order.products.length; j++) {
//       const expandedOrder = { ...order, product_id: order.products[j].id, product_name:  };
//       delete expandedOrder.products;
//       expandedOrders.push(expandedOrder);
//     }
//   }

//   return expandedOrders;
// };

export const sortOrders = (orders, sortType, isAscend) => {
  isAscend = isAscend ? 1 : -1;
  let sortFn;

  switch (sortType) {
    case "ID":
      sortFn = (a, b) => compareStrWithAscend(a.order_id, b.order_id, isAscend);
      break;

    case "Product":
      sortFn = (a, b) =>
        compareStrWithAscend(a.product_name, b.product_name, isAscend);
      break;

    case "Customer":
      sortFn = (a, b) => compareStrWithAscend(a.customer, b.customer, isAscend);
      break;

    case "Date":
      sortFn = (a, b) =>
        isAscend * (getDateTime(a.order_date) - getDateTime(b.order_date));
      break;
    default:
      throw new Error("Unsupported Sort Type");
  }
  return [...(orders ?? [])].sort(sortFn);
};

export const filterOrders = (orders, filterType) => {
  let filterFn;
  // switch (filterType) {
  //   case "All":
  //     filterFn = (a) => true;
  //     break;

  //   case "No action yet":
  //     filterFn = (a) => a.order_action === "No action yet";
  //     break;

  //   case "Accepted":
  //     filterFn = (a) => a.order_action === "Accepted";
  //     break;

  //   case "Rejected":
  //     filterFn = (a) => a.order_action === "Rejected";
  //     break;
  //   default:
  //     throw new Error("Unsupported Filter Type");
  // }
  // return (orders ?? []).filter(filterFn);
  return (orders ?? []).filter(() => true);
};

export const getPopularity = (state, product) => {
  return (state.popularities ?? []).filter(
    (pop) => pop.product_id === product.sno
  ).length;
};

function getDateTime(date) {
  return new Date(date).getTime();
}

function compareStrWithAscend(a, b, isAscend) {
  return isAscend * compareStr(a, b);
}

function compareStr(a, b) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export const getFromOffsetToLimit = (arr, offset, limit) => {
  return arr.slice(offset, offset + limit);
};

export const truncateNum = (num, min, max) => {
  if (num < min) return min;
  if (num > max) return max;
  return num;
};

export const filterOrdersBySearch = (orders, searchValue) => {
  return (orders ?? []).filter((order) =>
    new RegExp(
      (searchValue ?? "")
        .replace(/\s*/g, "")
        .replace(/[\\[.+*?(){|^$]/g, "\\$&"),
      "i"
    ).test(
      (
        order.order_id +
        order.customer +
        order.product_name +
        order.address
      ).replace(/\s*/g, "")
    )
  );
};
