import React, { Component } from "react";
import Swal from "sweetalert2";

import leftArrowIcon from "../../assets/images/arrow-left.svg";
import { Link } from "react-router-dom";
import { ProductService } from "../../api";
import io from "socket.io-client";
import Layout from "../layouts/pageLayouts";
import { InputLabel, SelectLabel, TextArea } from "../inputFields/Input";
import { PrimaryBtn } from "../button/Button";
import { obj } from "../../constants";
import UploadImageInput from "./UploadImageInput";

const endpoint = obj.serverurl;

let socket;

const photoKeys = ['photo1', 'photo2', 'photo3', 'photo4']

class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      price: "",
      dimension: "",
      exhibitoremail: "",
      currency: "",
      delivery_type: "",
      photos: {},
      stock: "",
    };
  }

  componentDidMount() {
    socket = io(endpoint);
    // var input = document.getElementById("photos");
    // var infoArea = document.querySelector(".file-label");
    // if (input) input.addEventListener("change", showFileName);
    // function showFileName(event) {
    //   var input = event.srcElement;
    //   var fileNames = [...input.files].map((file) => file.name);
    //   infoArea.setAttribute("style", "color: #fff !important");
    //   infoArea.style.background = " grey repeat-x center";
    //   infoArea.style.fontSize = "16px";
    //   infoArea.style.fontWeight = "400";
    //   infoArea.style.textAlign = "center";
    //   infoArea.textContent = fileNames.join(", ");
    // }
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFile = (e) => {
    e.preventDefault();
    this.setState({ photos: e.target.files });
    console.log(e.target.files);
  };

  setPhotos(photos) {
    this.setState({ photos });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const exhibitoremail = sessionStorage.getItem("merchantemail");
    const data = {
      name: this.state.name,
      description: this.state.description,
      price: this.state.price,
      currency: "USD",
      dimension: this.state.dimension,
      delivery_type: this.state.delivery_type,
      exhibitoremail,
      stock: this.state.stock,
    };
    const form = new FormData();
    for (const key in data) {
      if (key === 'photos') continue;
      if (data.hasOwnProperty(key)) {
        form.append(key, data[key]);
      }
    }

    let hasAtLeastOnePhoto  = false;
    for (const photoKey of photoKeys) {
      const photo = this.state.photos[photoKey];
      if (!photo) continue;
      form.append(photoKey, photo);
      hasAtLeastOnePhoto = true;
    }

    if (!hasAtLeastOnePhoto)
      return Swal.fire("Error", 'Please add product image and other required fields', "error");
   
    ProductService.addproduct(form).then((res) => {
      console.log("Add res", res);
      if (res.success) {
        socket.emit("addproduct", { message: "New product!" });

        Swal.fire(
          "Success",
          "Product has been successfully published",
          "success"
        );
        setTimeout(() => {
          window.location = "/products";
        }, 2000);
      } else {
        Swal.fire("Error", res.msg, "error");
      }
    });
  };

  render() {
    return (
      <>
        <Layout>
          <div className="col-12">
            <h4 className="d-flex align-items-center">
              <Link to="/products" className="mr-3">
                <img src={leftArrowIcon} alt="Go back" />
              </Link>
              <span className="__title">Add product</span>
            </h4>
          </div>
          <div className="product__form-container col-12">
            <form className="product__form" onSubmit={this.handleSubmit}>
              <div className="form-group pb-2">
                <InputLabel
                  label="Product Name"
                  name="name"
                  onChange={this.handleChange}
                  placeholder="`Your product name`"
                  require
                />
              </div>
              <div className="form-row pb-2">
                <div className="form-group col-md-6">
                  <InputLabel
                    label="Price"
                    type="number"
                    name="price"
                    value={this.state.price}
                    onChange={this.handleChange}
                    placeholder="`Your product price`"
                    require
                  />
                </div>
                <div className="form-group col-md-6">
                  <InputLabel
                    label="Dimension (optional)"
                    name="dimension"
                    value={this.state.dimension}
                    onChange={this.handleChange}
                    placeholder="`Your product size`"
                  />
                </div>
              </div>
              <div className="form-row pb-2">
                <div className="form-group col-md-6">
                  <InputLabel
                    label="Stock"
                    name="stock"
                    value={this.state.stock}
                    onChange={this.handleChange}
                    placeholder="`Number of products available`"
                    require
                  />
                </div>
                <div className="form-group col-md-6">
                  <SelectLabel
                    label="Delivery Type"
                    choices={["By Land", "By Sea", "By Air"]}
                    name="delivery_type"
                    defaultValue="--select delivery type--"
                    value={this.state.delivery_type}
                    onChange={this.handleChange}
                    require
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12">
                  <TextArea
                    label="Description"
                    name="description"
                    rows="5"
                    value={this.state.description}
                    onChange={this.handleChange}
                    placeholder="`Describe your product`"
                    require
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12">
                  <label htmlFor="photos" className="__labe_l">
                    Product Image(s)
                  </label>
                  <UploadImageInput
                  photoKeys={photoKeys}
                    photos={this.state.photos}
                    addPhoto={(key, photo) => 
                      this.setState(s => ({
                        ...s,
                        photos: {...s['photos'], [key]: photo} 
                      }))
                    }
                    removePhoto={(key) => 
                      this.setState(s => ({
                        ...s,
                        photos: {...s['photos'], [key]: undefined} 
                      }))
                    }
                  />
                  {/* <label htmlFor="photos" className="file-label">
                    <Input
                      type="file"
                      name="photos"
                      accept="image/*"
                      multiple
                      // value={this.state.photos}
                      onChange={this.handleFile}
                      required
                      id="photos"
                    />
                    Upload +
                    <br /> (Select multiple product thumbnail images)
                  </label> */}
                </div>
              </div>
              <PrimaryBtn text="Create" onClick={this.handleSubmit} block  />
            </form>
          </div>
        </Layout>
      </>
    );
  }
}

export default AddProduct;
