import { BrowserRouter as Router, Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import {
    ProductList,
    AddProduct,
    Product,
    Order,
    OrderList,
    ChatList,
    Profile,
    Notifications,
    // Login,
} from "./components";
import EditProduct from "./components/Products/editProduct";
import Error404 from "./components/error/Error404";
import NewLogin from "./pages/login";
import NewSignup from "./pages/signup";
import EditProfile from "./components/Profile/editProfile";
import AddMultiProduct from "./components/Products/addmutipleproduct";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" exact element={<NewLogin />} />
                <Route path="/login" exact element={<NewLogin />} />
                <Route path="/signup" exact element={<NewSignup />} />
                <Route path="/products/:id" exact element={<Product />} />
                <Route path="/products/" exact element={<ProductList />} />
                <Route path="/addproduct" exact element={<AddProduct />} />
                <Route path="/add-mutltiple-product" exact element={<AddMultiProduct />} />
                <Route path="/orders/" exact element={<OrderList />} />
                <Route path="/orders/:orderid/:productid" exact element={<Order />} />
                <Route path="/chats" element={<ChatList />} />
                <Route path="/profile" exact element={<Profile />} />
                <Route path="/edit-profile" exact element={<EditProfile />} />
                <Route
                    path="/notifications"
                    exact
                    element={<Notifications />}
                />
                <Route
                    path="/edit-product/:id"
                    exact
                    element={<EditProduct />}
                />
                <Route path="*" element={<Error404 />} />
            </Routes>
        </Router>
    );
}

export default App;
