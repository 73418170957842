import React, { useState } from 'react'
import { UpOutlined, DownOutlined } from '@ant-design/icons'

const TableColHead = ({ text, isAscend, isActive, handleClick }) => {
  const [isIconVisible, setIsIconVisible] = useState(isActive)
  const Icon = isAscend ? UpOutlined : DownOutlined

  return (
    <div className=".__table-col-head" onHover={() => {
      setIsIconVisible(true)
      console.log("Hovered on")
    }} onMouseOver={() => {
      setIsIconVisible(true)
      console.log("Moused over on")
    }} onMouseOut={() => {
      setIsIconVisible(isActive)
      console.log("Moused out on")
    }} onTouch>
      <span>{text}</span>
      <Icon
        onClick={() => handleClick(!isAscend)}
        style={{ visibility: isIconVisible ? 'visible' : 'hidden' }}
      />
    </div>
  )
}

export default TableColHead
