import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { MerchantService } from "../api";

import logo from "../assets/images/eamr-logo.PNG";
import "../assets/auth.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = { username: this.state.email, password: this.state.password };
    MerchantService.login(data)
      .then((res) => {
        if (res.success) {
          Swal.fire("Success", "Logged in", "success");
          sessionStorage.setItem("merchantid", res.user.merchant_id);
          sessionStorage.setItem("merchantemail", res.user.email);
          setTimeout(() => {
            window.location = "/products";
          }, 2000);
        } else {
          Swal.fire("Sorry", res.message, "error");
        }
      })
      .catch((err) => {
        Swal.fire("Sorry", err.message, "error");
      });
  };

  render() {
    return (
      <div
        className="text-center auth-container"
        style={{ backgroundColor: "#007bff", color: "#fff", height: "100%" }}
      >
        <img src={logo} alt="EAMR Logo" width={100} />
        <h3>EAMR</h3>
        <h3>Log In/Sign Up</h3>
        <div>
          <form className="auth-form" onSubmit={this.handleSubmit}>
            <div className="text-center form-group">
              <select name="" id="" className="auth-input">
                <option value="">Select login type</option>
                <option value="">Loign type1</option>
                <option value="">Loign type2</option>
              </select>
            </div>
            <div className="text-center form-group">
              <label htmlFor="">Username</label>
              <input
                type="email"
                placeholder="Username"
                className="auth-input text-dark"
                name="email"
                onChange={this.handleChange}
              />
            </div>
            <div className="text-center form-group">
              <label htmlFor="">Password</label>
              <input
                type="password"
                placeholder="Password"
                className="auth-input text-dark"
                name="password"
                onChange={this.handleChange}
              />
            </div>
            <div>
              <p>
                <Link to="/forgot-password" className="link">
                  Forgot Password
                </Link>
              </p>
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-primary deep-btn"
                onClick={this.handleSubmit}
              >
                Log In
              </button>
            </div>
          </form>
          <div>
            <Link className="btn btn-outline-light" to="/signup">
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
