import React, { Component } from "react";
import verifyMerchant from "../../auth";
import { Link } from "react-router-dom";

import productIcon from "../../assets/images/product-icon.svg";
import orderIcon from "../../assets/images/order-ico.svg";
import chatIcon from "../../assets/images/chat-bullet.svg";
import profileIcon from "../../assets/images/profile-icon.svg";
import eamrlogo from "../../assets/images/eamr-logo.PNG";

import "../../App.css";

class LeftSideBar extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    verifyMerchant();
  }

  logout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("merchantid");
    sessionStorage.removeItem("merchantemail");
    window.location = "/login";
  };

  render() {
    return (
      <div
        className="d-flex flex-column align-items-stretch flex-shrink-0"
        style={{
          width: "200px",
          position: "fixed",
          top: "0px",
          backgroundColor: "#1B55AB",
          height: "100%",
        }}
      >
        <a
          href="/"
          className="d-flex align-items-center flex-shrink-0 p-3 link-dark text-decoration-none"
        >
          <img src={eamrlogo} alt="Logo" width={50} />
          <h3 style={{ color: "#fff" }}>EMR</h3>
        </a>

        <div
          className="list-group  border-bottom scrollarea"
          style={{ backgroundColor: "#1B55AB" }}
        >
          <Link to="/products" className="list-group-it py-3 ">
            <div className="w-100 d-flex align-items-center">
              <img
                src={productIcon}
                alt="product_icon"
                style={{ marginRight: "10px" }}
              />
              <span>Product</span>
            </div>
          </Link>
          <Link to="/orders" className="list-group-it py-3 lh-tight">
            <div className="w-100 d-flex align-items-center">
              <img
                src={orderIcon}
                alt="product_icon"
                style={{ marginRight: "10px" }}
              />
              <span>Order</span>
            </div>
          </Link>
          <Link to="/chats" className="list-group-it py-3 lh-tight">
            <div className="w-100 d-flex align-items-center">
              <img
                src={chatIcon}
                alt="product_icon"
                style={{ marginRight: "10px" }}
              />
              <span>Chat</span>
            </div>
          </Link>

          <Link to="/profile" className="list-group-it py-3 lh-tight">
            <div className="w-100 d-flex align-items-center">
              <img
                src={profileIcon}
                alt="product_icon"
                style={{ marginRight: "10px" }}
              />
              <span>Profile</span>
            </div>
          </Link>
        </div>
        <Link
          to="/"
          className=" py-3 lh-tight"
          style={{ color: "#fff", padding: "5px 10px" }}
          onClick={this.logout}
        >
          <div className="w-100 d-flex align-items-center">
            <span>Logout</span>
          </div>
        </Link>
      </div>
    );
  }

  // <MenuWrapper mode="inline" theme="light">
  //   <Menu.Item key="1" icon={<DashboardOutlined />}>
  //     <Link to="/dashboard">Products</Link>
  //   </Menu.Item>

  //   <Menu.Item key="2" icon={<SettingOutlined />}>
  //     <Link to="/dashboard/orders-list">Order</Link>
  //   </Menu.Item>

  //   <Menu.Item key="3" icon={<SendOutlined />}>
  //     <Link to="/dashboard/chat">Chat</Link>
  //   </Menu.Item>

  //   <Menu.Item key="4" icon={<ReadOutlined />}>
  //     <Link to="/dashboard/profile">Profile</Link>
  //   </Menu.Item>
  // </MenuWrapper>
}
export default LeftSideBar;
