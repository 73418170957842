import React, { Component } from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";

import productImg from "../../assets/images/product-img1.png";
import leftArrowIcon from "../../assets/images/arrow-left.svg";
import locationIcon from "../../assets/images/location.PNG";
import PageLayout from "../layouts/pageLayouts";
import { PrimaryBtn, SecBtn } from "../button/Button";
import {Order as OrderApi} from "../../api"
import Notify from "cogo-toast";

const { Title } = Typography;
class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      productImages: ["../../assets/images/product-img1.png"],
      orderID: "",
      orderDetail: {}
    };
  }

  componentDidMount() {
    const orderID = window.location.pathname.split("/")[2];
    const productID = window.location.pathname.split("/")[3];
    this.setState({ orderID });
    OrderApi.fetchOrderDetails({orderid: orderID, productid: productID}).then(res => {
      if(res.success) {
        this.setState({orderDetail: res.data});
        const chatdetail =
            res.data.orderid +
            "_" +
            res.data.buyeremail +
            "_" +
            res.data.product_id;
        sessionStorage.setItem("chatdetail", chatdetail);
      } else {
        Notify.error("An error Occurred");
        // this.props.location.push("/orders")
        window.location.href = "/orders";
      }
    }).catch(err => {
      console.log(err);
      // this.props.location.push("/orders")
      window.location.href = "/orders";
    })
  }

  formatDate = (day) => {
    return day < 10 ? "0" + day : day;
  };

  render() {
    return (
      <>
        <PageLayout>
          <div className="col-12">
            <h4 className="d-flex align-items-center">
              <Link to="/orders" className="mr-3">
                <img src={leftArrowIcon} alt="Go back" />
              </Link>
              <span className="__title">ORDER ID: {this.state.orderID}</span>
            </h4>
          </div>
          <div className="order__container d-flex row justify-content-start align-items-start">
            <div className="product__image-container col-lg-9">
              <Title level={5}>Shipping Information</Title>
              <div className="order__products border-bottom">
                <div className="order__product-container">
                  <div className="order__product-info justify-content-between d-flex">
                    <div className="d-flex align-items-center col-8">
                      <img
                        src={this.state.orderDetail.product_photo || productImg}
                        alt=""
                        className="order__product-img"
                      />
                      <div>
                        <Title level={4}>{this.state.orderDetail.product_name} (Product ID: {this.state.orderDetail.product_id})</Title>
                        <p className="text-muted small">
                          {this.state.orderDetail.product_description}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center col-3 text-right">
                      <div>
                        <p>x {this.state.orderDetail.product_quantity}</p>
                        <p className="font-weight-bold text-right">${this.state.orderDetail.product_price}.00</p>
                      </div>
                      <a
                        href="/#"
                        style={{
                          color: "#24ad66",
                          //   fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        Update Status
                      </a>
                    </div>
                  </div>
                  <div className="order__shipping-timeline mt-5">
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <ul class="step-wizard-list">
                            <li class="step-wizard-item">
                              <span class="progress-count">1</span>
                              <span class="progress-label">
                                <p className="small text-muted">
                                  {this.formatDate(new Date(this.state.orderDetail.order_date).getDate())}-
                                  {this.formatDate(new Date(this.state.orderDetail.order_date).getMonth() +1)}-
                                  {this.formatDate(new Date(this.state.orderDetail.order_date).getFullYear()) + " "}
                                  
                                  {this.formatDate(new Date(this.state.orderDetail.order_date).getHours() + 1)}:
                                  {this.formatDate(new Date(this.state.orderDetail.order_date).getMinutes() +1)}
                                  {/* 04:00pm */}
                                </p>
                                <p className="small font-weight-bold">
                                  Waiting for Delivery
                                </p>
                                <p className="text-muted small">
                                  Booking is made on 
                                  {" " + this.formatDate(new Date(this.state.orderDetail.order_date).getDate())}-
                                  {this.formatDate(new Date(this.state.orderDetail.order_date).getMonth() +1)}-
                                  {this.formatDate(new Date(this.state.orderDetail.order_date).getFullYear()) + " "}
                                   {/* by Customer Name */}
                                </p>
                              </span>
                            </li>
                            <li class="step-wizard-item current-item">
                              <span class="progress-count">2</span>
                              <span class="progress-label">
                                <p className="small text-muted">
                                  {/* 2021-01-01 04:00pm */}
                                </p>
                                <p className="small font-weight-bold">
                                  Delivery in Progress
                                </p>
                                <p className="text-muted small">
                                  {/* Booking is made on 2021-04-01  */}
                                  {/* by Customer Name */}
                                </p>
                              </span>
                            </li>
                            <li class="step-wizard-item">
                              <span class="progress-count">3</span>
                              <span class="progress-label">
                                <p className="small text-muted">
                                  {/* 2021-01-01 04:00pm */}
                                </p>
                                <p className="small font-weight-bold">
                                  Delivered
                                </p>
                                <p className="text-muted small">
                                  {/* Booking is made on 2021-04-01  */}
                                  {/* by Customer Name */}
                                </p>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-start justify-content-between border-bottom"
                style={{ padding: "20px 0px" }}
              >
                <img src={locationIcon} alt="" width={20} classname="col-2" />
                <div className="col-10">
                  <p className="font-weight-bold">Address</p>
                  <p className="text-muted small">
                    {this.state.orderDetail.shipping_address}
                  </p>
                </div>
                <a href="/#" className="text-decoration-none">
                  Change
                </a>
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Title level={5}>Shipping Information</Title>
                <div>
                  <div
                    className="d-flex justify-content-between"
                    style={{ margin: "0px" }}
                  >
                    <h6 className="font-weight-bold">{this.state.orderDetail.product_name}</h6>
                    <p className="text-right font-weight-bold">${this.state.orderDetail.product_price}.00</p>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{ margin: "0px" }}
                  >
                    <p className="text-muted small">
                    {this.state.orderDetail.product_description}
                    </p>
                    {/* <p className="text-muted small col-2 text-right">
                      Product Varian X1
                    </p> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 order__summary-container border bg-white rounded">
              <div className="border-bottom">
                <div className="d-flex justify-content-between">
                  <p>Subtotal</p>
                  <p className="text-right">${this.state.orderDetail.product_price * this.state.orderDetail.product_quantity}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Shipping Fee</p>
                  <p className="text-right">$0.00</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Tax</p>
                  <p className="text-right">7%</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="font-weight-bold">Total</p>
                  <p className="text-right font-weight-bold">${this.state.orderDetail.product_price * this.state.orderDetail.product_quantity}.00</p>
                </div>
              </div>
              <div className="d-flex border-bottom">
                <p className="font-weight-bold">Payment Method</p>
                <p className="d-flex justify-content-between">
                  <span
                    style={{
                      background: "#bbb",
                      height: "20px",
                      width: "20px",
                      margin: "0px 10px",
                    }}
                  ></span>
                  <span>PAYPAL</span>
                </p>
              </div>
              <div className="border-bottom">
                <div className="d-flex justify-content-between">
                  <p>Order Num:</p>
                  <p className="text-right">{this.state.orderDetail.orderid}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Order Payment:</p>
                  <p className="text-right">
                    {this.formatDate(new Date(this.state.orderDetail.order_date).getDate())}/
                    {this.formatDate(new Date(this.state.orderDetail.order_date).getMonth() +1)}/
                    {this.formatDate(new Date(this.state.orderDetail.order_date).getFullYear())}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="">Order Date:</p>
                  <p className="text-right">
                    {this.formatDate(new Date(this.state.orderDetail.order_date).getDate())}/
                    {this.formatDate(new Date(this.state.orderDetail.order_date).getMonth() +1)}/
                    {this.formatDate(new Date(this.state.orderDetail.order_date).getFullYear())}
                  </p>
                </div>
              </div>
              <div>
                <div className="mt-3 mb-2">
                  <Link to={"/chats?rm=" + this.state.orderID}>
                    <PrimaryBtn text="Chat Customer" block />
                  </Link>
                </div>
                <Link to={`/products/${this.state.products.sno}`}>
                  <SecBtn text="View Review" block />
                </Link>
              </div>
            </div>
          </div>
        </PageLayout>
      </>
    );
  }
}

export default Order;
