import React from 'react';
import starIcon from "../../assets/images/star-yellow-ico.svg";

const SingleReview = (props) => {
  return (
    <div className="col-md-6">
      <div className="product__review-container bg-white border rounded p-3">
        <div className="product__review-header d-flex align-items-center">
          <img
            src={props.avatar}
            alt=""
            className="review-avatar rounded-circle"
          />
          <div className="">
            <span className="font-weight-bold">{props.customerName}</span>
            <div>
              {
                [...Array(props.rating)].map(() => 
                  <img src={starIcon} alt="" className="star-rating-img" />
                )
              }
            </div>
          </div>
        </div>
        <div className="product__review-comment text-muted small pt-2">
          {props.comment}
        </div>
      </div>
    </div>
  );
};

export default SingleReview;
