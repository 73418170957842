export { default as Login } from './login';
export { default as Signup } from './signup';
export { default as ProductList } from "./Products/productlist";
export { default as AddProduct } from "./Products/addproduct";
export { default as Product } from "./Products/Product";
export { default as Order } from "./Orders/Order";
export { default as OrderList } from "./Orders/orderlist";
export { default as ChatList } from "./Chat/ChatList";
export { default as Profile } from "./Profile/Profile";
export { default as Notifications } from "./Notification/notificationList";

