import React, { useRef } from "react";
import map from "lodash/map";
import ImgPreview from "./ImgPreview";
import "./style.css"


const UploadImageInput = ({ photoKeys, photos, addPhoto, removePhoto }) => {
  const imageInputRef = useRef();

  return (
    <div className="uii__container">
      <div className="uii__img_preview-container">  
        {map(photoKeys, (photoKey) => {
          return (
            <ImgPreview key={photoKey}  photo={photos[photoKey]} onDeleteClick={() => {
              removePhoto(photoKey)
            }} />
          )
        })}

      </div>
      <div className="uii__input-container">
        <input
          ref={imageInputRef}
          type="file"
          name="photos"
          id="photos"
          accept="image/*"
          multiple
          className="uii__input"
          onChange={(e) => {
            e.preventDefault();
            const newFiles = e.target.files;

            let currNewFileIndex = 0;
            for (let i = 0; i < photoKeys.length; i++) {
              const photoKey = photoKeys[i]
              const photo = photos[photoKey];
              if (photo) continue;
              addPhoto(photoKey, newFiles[currNewFileIndex++])
            }
          }}
          required={true}
        />
        <button
        className="uii__btn-input"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation();
            imageInputRef.current.click();
          }}
        >
          Upload +
        </button>
        <br /> (Select multiple product thumbnail images)
      </div>
    </div>
  );
};

export default UploadImageInput;
