import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Image, Radio, Space, Typography } from "antd";

import { MerchantService } from "../api";
import logo from "../assets/images/logo.png";
import illus from "../assets/images/auth.svg";
import {
  InputLabel,
  TextArea,
  PasswordInput,
} from "../components/inputFields/Input";
import { PrimaryBtn } from "../components/button/Button";

const { Text } = Typography;

class NewSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "",
      mailing_address: "",
      email: "",
      reg_no: "",
      phone: "",
      dob: "",
      description: "",
      gender: "",
      password: "",
      confirmpassword: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.password !== this.state.confirmpassword) {
      Swal.fire("Error", "Password and confirm password does not match", "error");
    } else {
      const data = {
        name: this.state.name,
        phone: this.state.phone,
        password: this.state.password,
        reg_no: this.state.reg_no,
        description: this.state.description,
        address: this.state.address,
        email: this.state.email,
        dob: this.state.dob,
        gender: this.state.gender,
        mailing_address: this.state.mailing_address,
      };
      MerchantService.signup(data)
        .then((res) => {
          // console.log("SIGN UP RES", res)
          if (res.success) {
            Swal.fire("Success", res.message, "success");
            setTimeout(() => {
              window.location = "/products";
            }, 2000);
          } else {
            Swal.fire("Sorry", res.message, "error");
          }
        })
        .catch((err) => {
          Swal.fire("Sorry", err.message, "error");
        });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div className="__auth_container">
        <div className="container mx-auto __auth_content">
          <div className="px-3 px-lg-0 row">
            <div className="col-lg-5 d-none d-lg-block px-0  text-center">
              <div className="__info_img ">
                <Image src={illus} preview={false} alt="login" width={500} />
              </div>
            </div>
            <div className="w-100 px-0 col-lg-7">
              <div className="__info_content ">
                <div className="__info_con mb-3">
                  <div className="d-flex align-items-center mb-3">
                    <Image
                      src={logo}
                      alt="EAMR Logo"
                      width={50}
                      preview={false}
                    />
                    <h3>EAMR</h3>
                  </div>
                  <h4>Create an Account.</h4>
                  <p>Please fill in valid details</p>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    {/* Company name */}
                    <div className="col-lg-6">
                      <div className="form-group">
                        <InputLabel
                          label="Company Name"
                          name="name"
                          value={this.state.name}
                          onChange={this.handleChange}
                          placeholder="`Enter your company name`"
                          require
                        />
                      </div>
                    </div>
                    {/* Company address */}
                    <div className="col-lg-6">
                      <div className="form-group">
                        <InputLabel
                          label="Address"
                          name="address"
                          value={this.state.address}
                          onChange={this.handleChange}
                          placeholder="`Enter your company address`"
                          require
                        />
                      </div>
                    </div>
                    {/* Email address */}
                    <div className="col-lg-6">
                      <div className="form-group">
                        <InputLabel
                          type="email"
                          label="Email address"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          placeholder="`e.g johndoe908@domain.com`"
                          require
                        />
                      </div>
                    </div>
                    {/* Company Reg No */}
                    <div className="col-lg-6">
                      <div className="form-group">
                        <InputLabel
                          label="Company Reg No#"
                          name="reg_no"
                          value={this.state.reg_no}
                          onChange={this.handleChange}
                          placeholder="`e.g. AB123456`"
                        />
                      </div>
                    </div>
                    {/* Phone number */}
                    <div className="col-lg-6">
                      <div className="form-group">
                        <InputLabel
                          type="tel"
                          label="Phone Number"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.handleChange}
                          placeholder="`e.g +234803 239 3334`"
                        />
                      </div>
                    </div>
                    {/* Mailing address */}
                    <div className="col-lg-6">
                      <div className="form-group">
                        <InputLabel
                          label="Mailing address"
                          name="mailing_address"
                          value={this.state.mailing_address}
                          onChange={this.handleChange}
                          placeholder="`Enter your mailing address`"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row">
                        {/* Date of birth */}
                        <div className="col-12">
                          <div className="form-group ">
                            <InputLabel
                              type="date"
                              label="Date of Birth"
                              name="dob"
                              value={this.state.dob}
                              onChange={this.handleChange}
                              placeholder="`Select date of birth`"
                            />
                          </div>
                          {/* Gender */}
                          <div className="col-12 pl-0 ml-0">
                            <div className="form-group">
                              <Space>
                                <Text type="secondary" strong>
                                  Gender:
                                </Text>
                                <Radio.Group onChange={this.handleChange} value={this.state.gender} name="gender">
                                  <Radio value="male">Male</Radio>
                                  <Radio value="female">Female</Radio>
                                </Radio.Group>
                              </Space>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Company description */}
                    <div className="col-lg-6">
                      <div className="form-group">
                        <TextArea
                          label="Company Description"
                          name="description"
                          rows="3"
                          value={this.state.description}
                          onChange={this.handleChange}
                          placeholder="`Describe your company`"
                        />
                      </div>
                    </div>
                    {/* Password */}
                    <div className="col-lg-6">
                      <div className="form-group">
                        <PasswordInput
                          label="Password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          placeholder="`**********`"
                          require
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <PasswordInput
                          label="Confirm Password"
                          name="confirmpassword"
                          value={this.state.confirmpassword}
                          onChange={this.handleChange}
                          placeholder="`**********`"
                          require
                        />
                      </div>
                    </div>
                    <div className="form-group col-12 mt-3">
                      <PrimaryBtn
                        block
                        text="Sign up"
                        onClick={this.handleSubmit}
                      />
                    </div>
                  </div>
                </form>
                <div className="text-center pb-3">
                  <span> Already have an account? </span>
                  <Link className="font-weight-bold" to="/login">
                    Log in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewSignup;
