import React, { Component } from "react";
import { LeftSideBar, Header } from "../layouts";
import { Link } from "react-router-dom";

import leftArrowIcon from "../../assets/images/arrow-left.svg";
import calendarIcon from "../../assets/images/calendar-ico.svg";
// import starIcon from "../../assets/images/star-yellow-ico.svg";
// import leftIcon from "../../assets/images/arrow-small-ico.svg";
import PageLayout from "../layouts/pageLayouts";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <PageLayout>
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <h4 className="text-left __title">Notifications</h4>
            </div>
          </div>
          <div className="col-12">
            <div className="product__form-container">
              <div className="notification__section small">
                <div className="mb-3">
                  <span>Today</span>
                </div>
                <Link to="/" className="border">
                  <div>
                    <p>
                      New order has been made at <strong>Product Name</strong>.
                      Check order and proceed
                    </p>
                    <span className="text-muted">1.00PM</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </PageLayout>
      </>
    );
  }
}

export default Notifications;
