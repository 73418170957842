import { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { CgAsterisk } from "react-icons/cg";
// Input field
export const InputField = ({ type, name, placeholder, value, ...rest }) => (
  <input
    className="form-control __custom_input"
    type={type || "text"}
    name={name}
    id={name}
    value={value}
    placeholder={placeholder}
    {...rest}
  />
);

// Input with label field
export const InputLabel = ({
  label,
  type,
  name,
  placeholder,
  value,
  require,
  ...rest
}) => (
  <div className="d-flex flex-column">
    <label htmlFor={name} className="__labe_l mb-2">
      {label}
      {require ? (
        <sup>
          <CgAsterisk className="text-danger small" />
        </sup>
      ) : (
        <></>
      )}
    </label>
    <InputField
      type={type || "text"}
      name={name}
      value={value}
      placeholder={placeholder}
      {...rest}
    />
  </div>
);

// Password Input
export const PasswordInput = ({
  label,
  type,
  name,
  placeholder,
  value,
  require,
  ...rest
}) => {
  const [show, setShow] = useState(true);
  const togglePasswordVisiblity = () => {
    setShow(!show);
  };
  return (
    <div className="d-flex flex-column">
      <label htmlFor={name} className="__labe_l mb-2">
        {label}
        {require ? (
          <sup>
            <CgAsterisk className="text-danger small" />
          </sup>
        ) : (
          <></>
        )}
      </label>
      <div className="position-relative">
        <input
          className="form-control __custom_input pr-5"
          type={show ? "password" : "text"}
          name={name}
          id={name}
          value={value}
          placeholder={placeholder}
          {...rest}
        />
        <div className=" pr-3 __eye_con">
          <button
            type="button"
            onClick={togglePasswordVisiblity}
            className="__eye_btn __bt_n"
          >
            {show ? <AiFillEyeInvisible /> : <AiFillEye />}
          </button>
        </div>
      </div>
    </div>
  );
};

// Select
export const SelectLabel = ({
  name,
  label,
  choices,
  value,
  defaultValue,
  require,
  ...rest
}) => {
  const currentValue = value || defaultValue;
  return (
    <div className="d-flex flex-column">
      <label htmlFor={name} className="__labe_l mb-2">
        {label}
        {require ? (
          <sup>
            <CgAsterisk className="text-danger small" />
          </sup>
        ) : (
          <></>
        )}
      </label>
      <select
        name={name}
        id={name}
        value={currentValue}
        className="__custom_input form-control"
        {...rest}
      >
        <option
          className="__default_op text-dark"
          value={defaultValue}
          disabled
        >
          {defaultValue}
        </option>
        {choices.map((choice, index) => (
          <option key={index} value={choice}>
            {choice}
          </option>
        ))}
      </select>
    </div>
  );
};
// Input with label field
export const TextArea = ({
  label,
  type,
  name,
  placeholder,
  value,
  require,
  ...rest
}) => (
  <div className="d-flex flex-column">
    <label htmlFor={name} className="__labe_l mb-2">
      {label}
      {require ? (
        <sup>
          <CgAsterisk className="text-danger small" />
        </sup>
      ) : (
        <></>
      )}
    </label>
    <textarea
      className="form-control __custom_txtarea"
      name={name}
      value={value}
      placeholder={placeholder}
      {...rest}
    />
  </div>
);
