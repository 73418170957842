export const sortProduct = (state, sortType, isAscend) => {
  isAscend = isAscend ? 1 : -1;
  let sortFn;

  if (sortType === "Price") {
    sortFn = (a, b) => isAscend * (parseFloat(a.price) - parseFloat(b.price));
  } else if (sortType === "Popularity") {
    sortFn = (a, b) => isAscend * (getPopularity(a) - getPopularity(b));
  } else if (sortType === "Name") {
    sortFn = (a, b) => isAscend * compare(a.name, b.name);
  } else {
    sortFn = (a, b) => (isAscend === -1 ? a.name < b.name : a.name > b.name);
  }

  console.log("sortFn", sortFn);
  console.log(state.products);
  return [...(state.products ?? [])].sort(sortFn);
};

export const getPopularity = (state, product) => {
  return (state.popularities ?? []).filter(
    (pop) => pop.product_id === product.sno
  ).length;
};

export function compare(a, b) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export const getFromOffsetToLimit = (arr, offset, limit) => {
  return arr.slice(offset, offset + limit);
};

export const truncateNum = (num, min, max) => {
  if (num < min) return min;
  if (num > max) return max;
  return num;
};

export const filterProduct = (products, searchValue) => {
  return (products ?? []).filter((product) =>
    new RegExp(
      (searchValue ?? "")
        .replace(/\s*/g, "")
        .replace(/[\\[.+*?(){|^$]/g, "\\$&"),
      "i"
    ).test((product.name + product.description).replace(/\s*/g, ""))
  );
};
