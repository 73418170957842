import React, { Component } from 'react';
import { FaAngleRight } from 'react-icons/fa';

import starIcon from '../../assets/images/star-yellow-ico.svg';
import { Link } from 'react-router-dom';
import { MerchantService } from '../../api';
import PageLayout from '../layouts/pageLayouts';
import { DangerBtn, PrimaryBtn } from '../button/Button';
import Swal from 'sweetalert2';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchant: {},
    };
  }

  componentDidMount() {
    const id = sessionStorage.getItem('merchantid');
    if (!id) {
      window.location = '/login';
    }
    MerchantService.fetchdetails(id).then((res) => {
      this.setState({ merchant: res.data });
    });
  }

  logout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem('merchantid');
    sessionStorage.removeItem('merchantemail');
    window.location = '/login';
  };

  deleteAccount = () => {
    const userEmail = sessionStorage.getItem('merchantemail');

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!',
    }).then((result) => {
      if (result.isConfirmed) {
        MerchantService.deleteaccount({ email: userEmail })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
              setTimeout(() => {
                window.location = '/signup';
              }, 1000);
            } else {
              Swal.fire('Sorry', res.message, 'error');
            }
          })
          .catch((err) => {
            Swal.fire('Sorry', err.message, 'error');
          });
      }
    });
  };

  render() {
    return (
      <>
        <PageLayout>
          <div className=' mx-auto w-full'>
            <div className='col-12'>
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='__title'>Profile</h4>
                <Link to={`/edit-profile`}>
                  <PrimaryBtn text='Edit' />
                </Link>
              </div>
            </div>
            <div className='col-12'>
              <div className='product__form-container bg-white p-4'>
                <div className='border-bottom'>
                  <div className='__details __profile__details'>
                    <p className='font-weight-bold'>Email</p>
                    <p>{this.state.merchant.email}</p>
                  </div>
                  <div className='__details __profile__details'>
                    <p className='font-weight-bold'>Phone Num</p>
                    <p className=''>{this.state.merchant.phone_number}</p>
                  </div>
                  <div className='__details __profile__details'>
                    <p className='font-weight-bold'>Date of Birth</p>
                    <p className=''>{this.state.merchant.dob}</p>
                  </div>
                  <div className='__details __profile__details'>
                    <p className='font-weight-bold'>Gender</p>
                    <p className=' text-capitalize'>
                      {this.state.merchant.gender}
                    </p>
                  </div>
                  <div className='__details __profile__details'>
                    <p className='font-weight-bold'>Password</p>
                    <p className=''>*******</p>
                  </div>
                  <div className='__details __profile__details'>
                    <p className='font-weight-bold'>Company reg No</p>
                    <p className=''>{this.state.merchant.company_reg_no}</p>
                  </div>
                  <div className='__details __profile__details'>
                    <p className='font-weight-bold'>Company Desc.</p>
                    <p className='small'>
                      {this.state.merchant.description?.length > 50
                        ? `${this.state.merchant.description?.slice(0, 50)}...`
                        : this.state.merchant.description}
                    </p>
                  </div>
                  <div className='__details __profile__details'>
                    <p className='font-weight-bold'>Star Rating</p>
                    <p className=' small'>
                      <img src={starIcon} alt='' />
                      <img src={starIcon} alt='' />
                      <img src={starIcon} alt='' />
                      <img src={starIcon} alt='' />
                      <img src={starIcon} alt='' />
                      5/5
                    </p>
                  </div>
                  <div className='d-flex justify-content-between __details'>
                    <p className='font-weight-bold'>Mailing Address</p>
                    <p className=' small'>
                      {this.state.merchant.mailing_address}
                    </p>
                  </div>
                </div>

                <div style={{ padding: '20px 10px' }}>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <p className='font-weight-bold' style={{ margin: '0px' }}>
                        Ongoing Orders
                      </p>
                      <p className='text-muted small'>Ongoing 20 orders</p>
                    </div>
                    <p className='text-right'>
                      <Link to='/orders' className='font-weight-bold text-dark'>
                        <FaAngleRight />
                      </Link>
                    </p>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <p className='font-weight-bold'>Chat</p>
                    </div>
                    <p className='text-right'>
                      <Link to='/chats' className='font-weight-bold text-dark'>
                        <FaAngleRight />
                      </Link>
                    </p>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <p className='font-weight-bold' style={{ margin: '0px' }}>
                        Terms and conditions
                      </p>
                      <p className='text-muted small'>
                        Look at our Terms & Condtions and Privacy Policy
                      </p>
                    </div>
                    <p className='text-right'>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='http://eamr.shop/terms'
                        className='font-weight-bold text-dark'
                      >
                        <FaAngleRight />
                      </a>
                    </p>
                  </div>

                  <div className='text-center mt-3'>
                    <Link to='#' className='' onClick={this.logout}>
                      <DangerBtn block text='Log out' />
                    </Link>
                  </div>
                  <div className='text-center mt-3'>
                    <button
                      to='#'
                      className='__signout'
                      onClick={this.deleteAccount}
                    >
                      Delete account
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageLayout>
      </>
    );
  }
}

export default Profile;
