import { MerchantService } from "./api";

const verifyMerchant = function () {
    const id = sessionStorage.getItem("merchantid");
    console.log(id);
    if (id) {
        MerchantService.fetchdetails(id).then((res) => {
            console.log(res);
            if (res.error || !res.success) {
                window.location = "/login";
            }
        });
    } else {
        window.location = "/login";
    }
};

export default verifyMerchant;
