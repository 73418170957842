import { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { FaRegBell, FaSearch } from 'react-icons/fa';
import {
  Layout,
  Menu,
  Image,
  Modal,
  Input,
  Badge,
  Avatar,
  Dropdown,
} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  UserOutlined,
} from '@ant-design/icons';

import logo from '../../assets/images/logo_.png';
import productIcon from '../../assets/images/product-icon.svg';
import orderIcon from '../../assets/images/order-ico.svg';
import chatIcon from '../../assets/images/chat-bullet.svg';
import profileIcon from '../../assets/images/profile-icon.svg';
import { SearchComponent } from './searchComponent';
// import notifyIcon from "../../assets/images/notification-ico.svg";

const { Header, Sider, Content, Footer } = Layout;

export default function Layoutt({
  children,
  notifications,
  headerInputValue,
  handleHeaderInputChange,
}) {
  const [collapsed, setCollapsed] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleToggle = () => setCollapsed(!collapsed);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const items = [
    { key: 'product', label: 'Products', path: '/products', img: productIcon },
    { key: 'orders', label: 'Orders', path: '/orders', img: orderIcon },
    { key: 'chat', label: 'Chats', path: '/chats', img: chatIcon },
    { key: 'profile', label: 'Profile', path: '/profile', img: profileIcon },
    { key: 'notifications', path: '/notifications' },
    { key: 'settings', path: '/settings' },
    { key: 'login', path: '/login' },
    { key: 'signup', path: '/signup' },
    { key: 'addproduct', path: '/addproduct' },
    { key: 'edit', path: '/edit-product/:id' },
    { key: 'productid', path: '/products/:id' },
    { key: 'orderid', path: '/orders/:id' },
    { key: 'editprofile', path: '/profile/:id' },
    { key: 'reviews', path: '/reviews' },
    { key: '/', path: '/' },
  ];

  const location = useLocation();
  const history = useNavigate();
  const [selectedKey, setSelectedKey] = useState(
    items.find((_item) => location.pathname.startsWith(_item.path)).key
    // items.find((_item) => _item.key)
  );

  const onClickMenu = (item) => {
    const clicked = items.find((_item) => _item.key === item.key);
    history(clicked.path);
  };
  useEffect(() => {
    setSelectedKey(
      items.find((_item) => location.pathname.startsWith(_item.path)).key
    );
  }, [location]);

  const currentPath = location.pathname;
  const isProductPage = currentPath === '/products';

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          breakpoint='md'
        >
          <div className='__logo d-flex align-items-center justify-content-center text-center py-4'>
            {collapsed ? (
              <Image
                width={40}
                src={logo}
                alt='logo'
                className='logo'
                preview={false}
              />
            ) : (
              <>
                <Image
                  width={40}
                  src={logo}
                  alt='logo'
                  className='logo'
                  preview={false}
                />{' '}
                <h5 className='text-center mb-0'>EAMR</h5>
              </>
            )}
          </div>
          <Menu
            theme='dark'
            mode='inline'
            selectedKeys={[selectedKey]}
            // activeKey={[selectedKey]}
            onClick={onClickMenu}
          >
            {items.slice(0, 4).map(({ key, label, path, img }) => (
              <Menu.Item key={key} icon={<img src={img} alt={label} />}>
                <NavLink end to={path}>
                  {label}
                </NavLink>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout className='site-layout'>
          <Header className='site-layout-background __layout__header'>
            <div className='__header-container'>
              <h5 className='my-0'>Merchant Panel</h5>
              <div>
                {isProductPage ? (
                  <button
                    className='__bt_n'
                    onClick={() => setIsModalVisible(true)}
                  >
                    <FaSearch size={20} color='white' />
                  </button>
                ) : null}

                <div className='d-flex'>
                  <Link to='/notifications' className='text-white mx-4'>
                    <Badge count={notifications ? notifications.length : 0}>
                      <FaRegBell size={23} className='text-white' />
                    </Badge>
                  </Link>
                  <div>
                    <Dropdown overlay={menu} trigger={['click']}>
                      <button
                        className='ant-dropdown-link __bt_n'
                        onClick={(e) => e.preventDefault()}
                      >
                        <Avatar
                          size={30}
                          icon={<UserOutlined className=' align-middle' />}
                          style={{ background: '#275dad' }}
                        />
                        <DownOutlined className='text-white' />
                      </button>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </Header>
          <Content
            className='site-layout-background template__content'
            // style={{
            //   margin: "24px 16px",
            //   padding: 24,
            // }}
          >
            {children}
          </Content>
          <Footer style={{ textAlign: 'center', background: '#f7fcff' }}>
            <p className='__ftcopy mb-0'>
              Copyright © {new Date().getFullYear()} - All rights reserved.
            </p>
          </Footer>
        </Layout>
      </Layout>

      <SearchModal
        isModalVisible={isModalVisible}
        handleSearch={() => {
          setIsModalVisible(false);
          // TODO: Arib add the searching functionality
        }}
        handleCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
      />
    </>
  );
}

// Dropdown menu
const menu = () => {
  const logout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem('merchantid');
    sessionStorage.removeItem('merchantemail');
    window.location = '/login';
  };
  return (
    <Menu>
      <Menu.Item key='0'>
        <Link to='/profile'>Profile</Link>
      </Menu.Item>
      {/*<Menu.Item key='1'>
        <Link to='/editprofile'>Settings</Link>
      </Menu.Item>*/}
      <Menu.Divider />
      <Menu.Item key='3'>
        <Link to='/' onClick={logout}>
          Log out
        </Link>
      </Menu.Item>
    </Menu>
  );
};

// Search Modal
function SearchModal({ isModalVisible, handleOk, handleSearch, handleCancel }) {
  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      title='Search Products'
      zIndex={9999}
    >
      <div className=''>
        {/* <Input.Search
          allowClear
          placeholder='Search'
          size='large'
          onSearch={handleSearch}
        /> */}
        <SearchComponent/>
      </div>
    </Modal>
  );
}
