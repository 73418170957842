import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import io from "socket.io-client";
import Notify from "cogo-toast";

import leftArrowIcon from "../../assets/images/arrow-left.svg";
import Layout from "../layouts/pageLayouts";
import { InputLabel, SelectLabel, TextArea } from "../inputFields/Input";
import { PrimaryBtn } from "../button/Button";
import { ProductService } from "../../api";
import { obj } from "../../constants";
import UploadImageInput from "./UploadImageInput";

const endpoint = obj.serverurl;

let socket;

const photoKeys = ['photo1', 'photo2', 'photo3', 'photo4'];

export default function EditProduct(props) {
  const [form, setForm] = useState({photos: []}); // For already available photos from server
  const [productid, setProductid] = useState("");
  const [price, setPrice] = useState(0);
  const [name, setName] = useState("");
  const [adminEditMinutes, setAdminEditMinutes] = useState(30);

  const handleChange = (e) => {
    e.preventDefault();
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const pushPhotosFromServerToState = (photosFromServer) => {
    setForm({...form, photos: {...form.photos, ...photosFromServer}});
  }

  useEffect(() => {
    socket = io(endpoint);
    const productID = window.location.pathname.split("/")[2];
    if (!productID) {
      return (window.location.href = "/products");
    } else {
      setProductid(productID);

      ProductService.fetchproduct(productID)
        .then((res) => {
          const lastEdit = new Date(res.data[0].last_edit).getTime();
          const nextEdit =  (adminEditMinutes * 60) - ((new Date().getTime() - lastEdit)/1000);
          if(nextEdit > 0){
            return window.location.href = "/products";
          }
          // console.log(res.data[0]);
          setPrice(res.data[0].price);
          setName(res.data[0].name);
          setForm((form) => {
            return {...form, ...res.data[0]}
          });

            const photosFromServer = {};
            for (let i = 0; i < photoKeys.length; i++ ) {
              const key = photoKeys[i]
              photosFromServer[key] = res.data[0][key];
            }
            pushPhotosFromServerToState(photosFromServer);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [price, name]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("id", productid);
    formData.append("price", price);
    formData.append("name", name);
    for (const key in form) {
      if (key === 'photos') continue;
      if (form.hasOwnProperty(key)) {
        formData.append(key, form[key]);
      }
    }

    let hasAtLeastOnePhoto  = false;
    for (const photoKey of photoKeys) {
      const photo = form.photos[photoKey];
      if (!photo) continue;
      formData.append(photoKey, photo);
      hasAtLeastOnePhoto = true;
    }

    if (!hasAtLeastOnePhoto)
      return Swal.fire("Error", 'Please add product image and other required fields', "error");
    console.log("From datta", formData)
    ProductService.updateproduct(formData)
      .then((res) => {
        if (res.success) {
          socket.emit("addproduct", { message: "One guy don add product!" });
          Notify("product details updated");
          window.location.href = "/products";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     name: "",
  //     description: "",
  //     price: "",
  //     dimension: "",
  //     exhibitoremail: "",
  //     currency: "",
  //     delivery_type: "",
  //     photos: [],
  //     stock: "",
  //   };
  // }

  return (
    <Layout>
      <div className="col-12">
        <h4 className="d-flex align-items-center">
          <Link to="/products" className="mr-3">
            <img src={leftArrowIcon} alt="Go back" />
          </Link>
          <span className="__title">Edit product</span>
        </h4>
      </div>
      <div className="product__form-container col-12">
        <form className="product__form" onSubmit={handleSubmit}>
          <div className="form-group pb-2">
            <InputLabel
              label="Product Name"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder="`Your product name`"
            />
          </div>
          <div className="form-row pb-2">
            <div className="form-group col-md-6">
              <InputLabel
                label="Price"
                type="number"
                name="price"
                value={form.price}
                onChange={handleChange}
                placeholder="`Your product price`"
              />
            </div>
            <div className="form-group col-md-6">
              <InputLabel
                label="Dimension (optional)"
                name="dimension"
                value={form.dimension}
                onChange={handleChange}
                placeholder="`Your product size`"
              />
            </div>
          </div>
          <div className="form-row pb-2">
            <div className="form-group col-md-6">
              <InputLabel
                label="Stock"
                name="stock"
                value={form.stock}
                onChange={handleChange}
                placeholder="`Number of products available`"
              />
            </div>
            <div className="form-group col-md-6">
              <SelectLabel
                label="Delivery Type"
                choices={["Land", "Water", "Air"]}
                name="delivery_type"
                defaultValue={form.delivery_type}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-12">
              <TextArea
                label="Description"
                name="description"
                rows="5"
                value={form.description}
                onChange={handleChange}
                placeholder="`Describe your product`"
              />
            </div>
          </div>
          <div className="form-row">
                <div className="form-group col-12">
                  <label htmlFor="photos" className="__labe_l">
                    Product Image(s)
                  </label>
                  <UploadImageInput
                  photoKeys={photoKeys}
                    photos={form.photos ?? {}}
                    addPhoto={(key, photo) => {
        
                      setForm(s => ({
                        ...s,
                        photos: {...s['photos'], [key]: photo} 
                      }))
                    }
                      
                    }
                    removePhoto={(key) => 
                      setForm(s => ({
                        ...s,
                        photos: {...s['photos'], [key]: null} 
                      }))
                    }
                  />
                </div>
              </div>
          <PrimaryBtn block text="Save Changes" onClick={handleSubmit} />
        </form>
      </div>
    </Layout>
  );
}
