import React, { Component, useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Notify from "cogo-toast";

import { Order } from "../../api/index";
import PageLayout from "../layouts/pageLayouts";
import { PrimaryBtn } from "../button/Button";
import EmptyResult from "../empty/Empty";
import {
  filterOrders,
  filterOrdersBySearch,
  formatOrderDate,
  getFromOffsetToLimit,
  sortOrders,
  truncateNum,
} from "./service";
import OrderActionStatus from "./orderActionStatus";
import OrderDropdown from "./orderDropdown";
import {
  ArrowDownOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import TableColHead from "./tableColHead";
import { Button, Dropdown } from "antd";
import FilterMenu from "./filterMenu";
import SortingMenu from "./sorting";
import SortOrder from "../SortOrder";
import Pagination from "../Pagination";

const pageLimit = 20;
function OrderList(props) {
  const [state, setState] = useState({
    orders: [],
    productsid: [],
  });
  const [sortType, setSortType] = useState("Date");
  const [filterType, setFilterType] = useState("All");
  const [isAscend, setIsAscend] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const merchantid = sessionStorage.getItem("merchantemail");
    if (!merchantid) {
      Notify.error("You need to be logged in");
      navigate("/login");
    } else {
      Order.getOrders({ id: merchantid, start: 1, end: 400 })
        .then((res) => {
          console.log("Order List Res", res);
          if (!res.success) {
            Notify.error("Error fetchig orders");
          } else {
            setState({
              orders: res.orders,
              productsid: res.productsID,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const setOrder = (order_id) => {
    const order = state.orders.filter((order) => order.order_id === order_id);
    const orderproducts = order[0].products_details
      .split(",")
      .map((prod) => parseInt(prod.split("_")[0]));
    const matchproducts = state.productsid.filter(
      (prod) => orderproducts.indexOf(prod) > -1
    );
    const chatdetail =
      order[0].order_id + "_" + order[0].profile_id + "_" + matchproducts[0];
    sessionStorage.setItem("chatdetail", chatdetail);
  };

  const rejectOrder = (order) => {
    Order.updateStatus({status: -1, orderid: order.orderid, id: order.id}).then(res => {
      if(res.success) {
        Notify.success("Order has been rejected!")
        return window.location.reload();
      }
      Notify.error("Error changing order status.");
    }).catch(err => {
      Notify.error("Error changing order status.");
      console.log(err);
    })
  }

  const acceptOrder = (order) => {
    Order.updateStatus({status: 1, orderid: order.orderid, userid: order.customeremail, id: order.id}).then(res => {
      if(res.success) {
        Notify.success("Order has been accepted!")
        return window.location.reload();
      }
      Notify.error("Error changing order status.");
    }).catch(err => {
      console.log(err);
      Notify.error("Error changing order status.");
    })
  }

  return (
    <>
      <PageLayout
        headerInputValue={searchValue}
        handleHeaderInputChange={(e) => {
          setSearchValue(e.target.value);
        }}
      >
        <div className="col-12">
          <div className="d-flex justify-content-between">
            <h4 className="text-left __order_title">Order List</h4>
          </div>
        </div>
        <div
          style={{ width: "fit-content", margin: "0 auto", marginTop: "8px" }}
        >
          <div className="__order-table-setting-container">
            <div className="__order-table-setting">
              <div className="d-flex align-items-center">
                <span className="mr-2">Filter by:</span>
                <OrderDropdown
                  menu={
                    <FilterMenu
                      handleClick={(value) => {
                        setFilterType(value);
                      }}
                    />
                  }
                  btnText={filterType}
                />
              </div>

              <div className="d-flex align-items-center">
                <span className="mr-2">Sort by:</span>
                <OrderDropdown
                  menu={
                    <SortingMenu
                      handleClick={(value) => {
                        setSortType(value);
                      }}
                    />
                  }
                  btnText={sortType}
                />
                <SortOrder
                  className={"ml-1"}
                  isAscend={isAscend}
                  handleClick={() => {
                    setIsAscend((s) => !s);
                  }}
                />
              </div>
            </div>
            <Pagination
              currentFirstNo={truncateNum(
                state.orders.length ? pageOffset + 1 : 0,
                0,
                state.orders.length
              )}
              currentLastNo={truncateNum(
                pageOffset + pageLimit,
                0,
                state.orders.length
              )}
              totalNo={state.orders.length}
              onPreviousClick={(e) => {
                e.stopPropagation();
                setPageOffset((o) => truncateNum(o - pageLimit, 0, o));
              }}
              onNextClick={(e) => {
                e.stopPropagation();
                setPageOffset((o) => {
                  const newOffset = truncateNum(
                    o + pageLimit,
                    0,
                    state.orders.length
                  );
                  console.log(
                    "New oF",
                    newOffset === state.orders.length ? o : newOffset
                  );
                  return newOffset === state.orders.length ? o : newOffset;
                });
              }}
            />
          </div>
          <table className="__table __h-50">
            <thead className="__sticky">
              <tr>
                <th scope="col">S/N</th>
                <th scope="col">ID</th>
                <th scope="col"></th>
                <th scope="col">Product</th>
                <th scope="col">Date</th>
                <th scope="col">Customer</th>
                <th scope="col">Address</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {/* {state.orders.length > 0 ? ( */}
              {state.orders.length > 0 ? (
                <>
                  {/* WARNING: Functionality of filterOrdersBySearch is disabled for now since the 'order_action' for each order is not yet being retrieved from the server.  
                
                By 'order_action', I mean whether the order has been 'accepted', 'rejected' or 'none' (not accepted or rejected yet) 
                */}
                  {filterOrdersBySearch(
                    getFromOffsetToLimit(
                      filterOrders(
                        sortOrders(state.orders, sortType, isAscend),
                        filterType
                      ),
                      pageOffset,
                      pageLimit
                    ),
                    searchValue
                  ).map((order, index) => {
                    const mOrderActionStatus = (
                      <OrderActionStatus
                        status={null}
                        acceptClick={(e) => {
                          e.stopPropagation();
                          acceptOrder(order);
                          console.log("Roder", order)
                        }}
                        rejectClick={(e) => {
                          e.stopPropagation();
                          rejectOrder(order);
                        }}
                      />
                    );
                    return (
                      <tr
                        key={order.order_id + order.product_id}
                        onClick={() =>
                          navigate(
                            "/orders/" + order.orderid + "/" + order.productid
                          )
                        }
                      >
                        {mOrderActionStatus}

                        <th data-title="S/N" scope="row">
                          <span className="__line-clamp-3"> {index + 1}</span>
                        </th>
                        <td data-title="ID">{order.orderid}</td>
                        <td data-title="Product Image">
                          <img src={order.product_photo} width="50px" alt="" />
                        </td>
                        <td data-title="Product">
                          <span className="__line-clamp-3">
                            {order.product_name}
                          </span>
                        </td>
                        <td data-title="Date">
                          <span className="__line-clamp-3">
                            {formatOrderDate(order.order_date)}
                          </span>
                        </td>
                        <td data-title="Customer">
                          <span className="__line-clamp-3">
                            {order.customer}
                          </span>
                        </td>

                        <td data-title="Address">
                          <span className="__line-clamp-3">
                            {order.address}
                          </span>
                        </td>
                        
                        <td data-title="Actions">{
                          order.status < 0 ? (<span style={{color:"Red"}}>{order.status == -1? "Rejected by you" : "Cancelled by customer"} </span>)  : (order.status > 0 ? <span style={{color:"green"}}>{order.status == 1? "Accepted" : "Completed"} </span> : mOrderActionStatus)
                        }
                        {/* {mOrderActionStatus} */}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  <tr className="text-center container mx-auto">
                    <td colSpan={8}>
                      <div className="mt-4">
                        <EmptyResult desc="No order." height={100} />
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </PageLayout>
    </>
  );
}
export default OrderList;
