import React from 'react'
import { Dropdown as AntDropdown, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'

const Dropdown = ({ btnText, menu, width }) => {
  return (
    <AntDropdown overlay={menu}>
      <Button
        // style={{
        //   overflow: 'hidden',
        //   width: width ?? 'auto',
        //   textAlign: 'right',
        // }}
      >
        {btnText} <DownOutlined />
      </Button>
    </AntDropdown>
  )
}

export default Dropdown
