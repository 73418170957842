import http from "axios";
import { obj } from "../constants";

const API_ROOT = obj.serverurl + "/api";
console.log("API_ROOT", API_ROOT);
// const API_ROOT = "http://ec2-175-41-178-95.ap-southeast-1.compute.amazonaws.com:6002/api";
const ADMIN_ROOT = obj.adminurl;
console.log("ADMIN_ROOT", ADMIN_ROOT);

const getOptions = (datatype = null) => {
  let options = {};
  let token = localStorage.getItem("token");
  if (token) {
    options = {
      ...options,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": datatype,
      },
    };
  }

  return options;
};

const response = (res) => res.data;

const request = {
  get: (url) => http.get(`${API_ROOT}${url}`, getOptions()).then(response),
  adminget: (url) => http.get(`${ADMIN_ROOT}${url}`, getOptions()).then(response),
  post: (url, data) =>
    http
      .post(`${API_ROOT}${url}`, data, getOptions("application/json"))
      .then(response),
  put: (url, data) =>
    http.put(`${API_ROOT}${url}`, data, getOptions()).then(response),
  patch: (url, data) =>
    http
      .patch(`${API_ROOT}${url}`, data, getOptions("application/json"))
      .then(response),
  delete: (url) =>
    http.delete(`${API_ROOT}${url}`, getOptions()).then(response),
};

const externalrequest = {
  get: (url) => http.get(`${url}`, getOptions()).then(response),
  post: (url, data) =>
    http.post(`${url}`, data, getOptions("application/json")).then(response),
  put: (url, data) => http.put(`${url}`, data, getOptions()).then(response),
  delete: (url) => http.delete(`${url}`, getOptions()).then(response),
};

// const Required = {};

const LoginService = {
  login: (data) => request.post("/customer/logincustomer", data),
  addcustomer: (data) => request.post("/customer/addcustomer", data),
  validateotp: (data) => request.post("/customer/validateotp", data),
  resendotp: (data) => request.post("/customer/resendotp", data),
  UENdata: () =>
    externalrequest.get(
      "https://data.gov.sg/api/action/datastore_search?resource_id=5ab68aac-91f6-4f39-9b21-698610bdf3f7&limit=50"
    ),
};

const ProductService = {
  addproduct: (data) => request.post("/product", data),
  changeprice: (data) => request.put("/merchant/editprice", data),
  fetchproduct: (id) => request.get("/product/" + id),
  producttime: (id) => request.get("/merchant/getproducttime" + id),
  productedittime: (data) => request.post("/merchant/getproducttime",data),
  addproductviacsv: (data) => request.post("/product/addproductviacsv",data),
  addcsvimage: (data) => request.post("/product/addcsvimage",data),
  deleteproduct: (data) => request.post("/product/deleteproduct", data),
  updateproduct: (data) => request.post("/product/updateproduct", data),
  fetchbuyers: (data) => request.post("/product/fetchbuyers", data),
  fetchreviews: (data) => request.post("/product/getReview", data)
};

const MerchantService = {
  signup: (data) => request.post("/merchant", data),
  login: (data) => request.post("/merchant/login", data),
  fetchproducts: (data) => request.post("/merchant/products", data),
  updatemerchant: (data) => request.post("/merchant/updatemerchant", data),
  fetchdetails: (id) => request.get("/merchant/" + id),
  deleteaccount: (data) => request.post("/auth/delete-account", data), 
  fetchpopularity: () => request.get("/merchant/fetchpopularity"),
};

const AdminService = {
  login: (uname, pwd) => request.adminget("/apiLogin.php?usertype=superadmin&username="+uname+"&password="+pwd),
};

const Chat = {
  getMerchantGroups: (data) => request.post("/merchant/chatgroups", data),
  getProductChats: (data) =>
    request.post("/chats/getmerchantproductchats", data),
  getLastChats: (data) => request.post("/chats/getmerchantlastchats", data),
  createGroup: (data) => request.post("/chats/creategroup", data),
  // fetchdetails: (id) => request.get("/merchant/" + id),
};

const Order = {
  getOrders: (data) => request.post("/orders/merchantorders", data),
  fetchOrderDetails: (data) =>
    request.post("/orders/viewmerchantorderdetails", data),
  updateStatus: (data) => request.post("/orders/updatestatus", data),
};

export { request, LoginService, ProductService, MerchantService, AdminService, Chat, Order };
