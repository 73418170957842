import React from 'react';
import { Button } from 'antd';

// status: 'Accepted' | 'Rejected'
const OrderActionStatus = ({ acceptClick, rejectClick, status }) => {
  return (
    <div className="__live__btn_container">
      {status === 'accepted' || status === 'rejected' ? (
        <p
          style={{
            color: status === 'accepted' ? 'green' : 'red',
            textTransform: 'capitalize',
          }}
        >
          {status}
        </p>
      ) : (
        <>
        <Button className='__order-btn-primary' onClick={acceptClick}>Accept</Button>
        <Button className='__order-btn-danger' onClick={rejectClick}>Reject</Button>
          {/* <button
            className="__live__btn __live__btn_update"
            onClick={acceptClick}
          >
            Accept
          </button>
          <button
            className="__live__btn __live__btn_delete"
            // onClick={() => alert('Todo Delete Live Deal')}
            onClick={rejectClick}
          >
            Reject
          </button> */}
        </>
      )}
    </div>
  )
}

export default OrderActionStatus
