import { useState, useEffect } from 'react';
import { MerchantService, ProductService } from '../../api';
import Swal from 'sweetalert2';
import ProductDetail from '../Products/ProductDetail';
import { useNavigate } from 'react-router-dom';
import Actions, { PriceChangeModal } from '../Products/Action';
import { InputLabel } from '../inputFields/Input';

export function SearchComponent() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [q, setQ] = useState('');
  const [searchParam] = useState(['sno', 'name', 'description']);
  const [filterParam, setFilterParam] = useState(['All']);
  const [adminEditMinutes, setAdminEditMinutes] = useState(30);
  const [isModalVisible, setIsModalVisible] = useState({
    modal: false,
    product: {
      sno: '',
      price: '',
    },
  });
  const navigate = useNavigate();

  console.log(items, 'items');

  useEffect(() => {
    const data = { email: sessionStorage.getItem('merchantemail') };
    MerchantService.fetchproducts(data)
      .then((res) => {
        if (res.success) {
          setItems(res.results);
          setIsLoaded(true);
        } else {
          Swal.fire('Sorry', 'Error fetching products', 'error');
        }
      })
      .catch((err) => {
        Swal.fire('Sorry', 'Error fetching products', 'error');
        setIsLoaded(true);
        setError(error);
      });
    MerchantService.fetchpopularity().then((res) => {
      console.log(res);
      if (res.success) {
        // setItems((state) => ({ ...state, popularities: res.results }));
      }
    });
  }, []);

  const data = Object.values(items);

  const deleteProduct = (id) => {
    // ProductService.deleteproduct({ id })
    //   .then((res) => {
    //     if (res.succes === 1) {
    //       const data = { email: sessionStorage.getItem('merchantemail') };
    //       const existingProducts = state.products.filter(
    //         (prod) => prod.sno !== id
    //       );
    //       setState((state) => ({ ...state, products: existingProducts }));
    //       message.success('Product deleted successfully!');
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  function search(items) {
    return items.filter((item) => {
      if (item.name.includes(q)) {
        return searchParam.some((newItem) => {
          return '';
          // return (
          //     item[newItem]
          //         .toString()
          //         .toLowerCase()
          //         .indexOf(q.toLowerCase()) > -1
          // );
        });
      } else if (filterParam.includes('All')) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  if (error) {
    return (
      <p>
        {error.message}, if you get this error, the free API I used might have
        stopped working, but I created a simple example that demonstrate how
        this works,{' '}
        <a href='https://codepen.io/Spruce_khalifa/pen/mdXEVKq'>
          {' '}
          check it out{' '}
        </a>{' '}
      </p>
    );
  } else if (!isLoaded) {
    return <>loading...</>;
  } else {
    return (
      <div className='wrapper'>
        <PriceChangeModal
          isModalVisible={isModalVisible.modal}
          handleSearch={() => {
            setIsModalVisible({ ...isModalVisible, modal: false });
            // TODO: Arib add the searching functionality
          }}
          price={isModalVisible?.product?.price}
          handleCancel={() =>
            setIsModalVisible({ ...isModalVisible, modal: false })
          }
          onOk={() => setIsModalVisible({ ...isModalVisible, modal: false })}
        />
        <div className='search-wrapper'>
          <label htmlFor='search-form'>
            <input
              type='search'
              name='search-form'
              id='search-form'
              className='search-input'
              placeholder='Search for...'
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
           
            <span className='sr-only'>Search countries here</span>
          </label>
        </div>
        <div>
            
        </div>
        {search(data).length > 0 &&
        <span className='mb-4 mt-8'>Search result:</span>}
        <ul className='card-grid'>
          {search(data).map((product, index) => {
            const actions = (
              <Actions
                onEditClick={(e) => {
                //   e.stopPropagation();
                  const lastEdit = new Date(product.last_edit).getTime();
                  const nextEdit =
                    adminEditMinutes * 60 -
                    (new Date().getTime() - lastEdit) / 1000;
                  nextEdit > 0
                    ? Swal.fire(
                        '',
                        `You have ${Math.floor(
                          nextEdit / 60
                        )} minutes until next edit for this product`,
                        'warning'
                      )
                    : navigate('/edit-product/' + product.sno);
                }}
                onPriceChangeClick={(e) => {
                  e.stopPropagation();
                  setIsModalVisible({
                    product: {
                      price: product?.price,
                      sno: product?.sno,
                    },
                    modal: true,
                  });
                }}
                
              />
            );
            return (
              <div
                className='result_tab'
                key={product.sno}
                onClick={() => navigate('/products/' + +product.sno)}
              >
                <tr>
                  <th data-title='S/N' scope='row'>
                    <span className='__line-clamp-3'>
                      {' '}
                      {/* {pageOffset + index + 1} */}
                    </span>
                  </th>
                  <td data-title='Product & Description'>
                    <ProductDetail product={product} />
                  </td>
                  <td data-title='Price ($)' className='text-right float-right ml-4'>
                    {`${product.price}.00`}
                  </td>
                </tr>
                <div data-title='Actions' className='actions-modal block'>
                  {actions}
                </div>
              </div>
            );
          })}
        </ul>
      </div>
    );
  }
}
