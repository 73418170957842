import React, { Component, useEffect, useState } from 'react';
import { Popconfirm, message, Tooltip, Image, Button } from 'antd';
// import { QuestionCircleOutlined } from '@ant-design/icons';
// import truncate from 'truncate';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { MerchantService, ProductService } from '../../api';
import { DangerBtn, PrimaryBtn, SecBtn } from '../button/Button';
import PageLayout from '../layouts/pageLayouts';
import EmptyResult from '../empty/Empty';
// import defaultImg from '../../assets/images/default.png';
import ProductDetail from './ProductDetail';
import Dropdown from '../Dropdown';
import Actions, { PriceChangeModal } from './Action';
import SortingMenu from './SortingMenu';
import SortOrder from '../SortOrder';
import Pagination from '../Pagination';
import {
  filterProduct,
  getFromOffsetToLimit,
  getPopularity,
  sortProduct,
  truncateNum,
} from './service';

const ProductList = (props) => {
  const [state, setState] = useState({
    // loading: false,
    products: [],
    popularities: [],
  });

  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);

  const [sortType, setSortType] = useState('Name');
  const [setPrice, setSetPrice] = useState();
  const [isAscend, setIsAscend] = useState(true);
  const [pageOffset, setPageOffset] = useState(0);
  const [adminEditMinutes, setAdminEditMinutes] = useState(30);
  const [isModalVisible, setIsModalVisible] = useState({
    modal: false,
    product: {
      sno: '',
      price: '',
    },
  });
  console.log(adminEditMinutes);
  const pageLimit = 20;

  const navigate = useNavigate();

  // init api call
  useEffect(() => {
    const data = { email: sessionStorage.getItem('merchantemail') };
    MerchantService.fetchproducts(data)
      .then((res) => {
        if (res.success) {
          setState((state) => ({ ...state, products: res.results }));
          setLoading(false);
        } else {
          Swal.fire('Sorry', 'Error fetching products', 'error');
        }
      })
      .catch((err) => {
        Swal.fire('Sorry', 'Error fetching products', 'error');
        setLoading(false);
      });
    MerchantService.fetchpopularity().then((res) => {
      console.log(res);
      if (res.success) {
        setState((state) => ({ ...state, popularities: res.results }));
      }
    });
    ProductService.productedittime(data).then((res) => {
      console.log(res.result[0], 'edittt');
      const result = res.result[0];
      const timeSplit = result?.product_edit_time;
      const time = timeSplit.split(':');
      const minutes = +time[0] * 60 + +time[1];
      setAdminEditMinutes(Number(minutes));
    });

    var minutes = 110 % 60;
    var hours = (110 - minutes) / 60;
    var output = hours + ':' + minutes;
    console.log(output);
  }, []);

  // delete product
  const deleteProduct = (id) => {
    ProductService.deleteproduct({ id })
      .then((res) => {
        if (res.success === 1) {
          const data = { email: sessionStorage.getItem('merchantemail') };
          const existingProducts = state.products.filter(
            (prod) => prod.sno !== id
          );
          setState((state) => ({ ...state, products: existingProducts }));
          message.success('Product deleted successfully!');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // edit product price
  const editProductPrice = () => {
    const data = {
      sno: isModalVisible.product.sno,
      price: setPrice,
    };
    ProductService.changeprice(data)
      .then((res) => {
        if (res.success === 1) {
          setIsModalVisible({ ...isModalVisible, modal: false });
          message.success('Product price updated!');
          setIsAscend(isAscend);
          setSetPrice();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };




  // if(loading) {
  //   return <div>loading...</div>

  // }
  return (
    <>
      <PageLayout
        headerInputValue={searchValue}
        handleHeaderInputChange={(e) => {
          setSearchValue(e.target.value);
        }}
      >
        <PriceChangeModal
          isModalVisible={isModalVisible.modal}
          handleSearch={() => {
            setIsModalVisible({ ...isModalVisible, modal: false });
            // TODO: Arib add the searching functionality
          }}
          handleSubmit={editProductPrice}
          setPric={setPrice}
          setSetPrice={setSetPrice}
          handleChangePrice={editProductPrice}
          price={isModalVisible?.product?.price}
          handleCancel={() =>
            setIsModalVisible({ ...isModalVisible, modal: false })
          }
          onOk={() => setIsModalVisible({ ...isModalVisible, modal: false })}
        />
        <div className='col-12'>
          <div className='d-flex justify-content-between flex-wrap'>
            <h3 className='__title'>Products List</h3>
            <div className='d-flex flex-wrap'>
              {/* <Link to="/" className="btn">
                Download List (PDF)
              </Link> */}
              <Link to='/add-mutltiple-product' className='mr-2'>
                <PrimaryBtn text='Upload CSV' />
              </Link>
              <Link to='/addproduct'>
                <PrimaryBtn text='Add Product' />
              </Link>
            </div>
          </div>
        </div>

        <div
          style={{ width: 'fit-content', margin: '0 auto', marginTop: '8px' }}
        >
          <div className='__order-table-setting-container'>
            <div className='__order-table-setting'>
              <div className='__sorting-container'>
                <div className='d-flex align-items-center'>
                  <span className='mr-2 sort-by'>Sort by:</span>
                  <Dropdown
                    menu={
                      <SortingMenu
                        handleClick={(value) => {
                          setSortType(value);
                        }}
                      />
                    }
                    btnText={sortType}
                  />
                </div>

                <SortOrder
                  isAscend={isAscend}
                  handleClick={() => {
                    setIsAscend((s) => !s);
                  }}
                />
              </div>
              <input
                type='search'
                name='search-form'
                id='search-form'
                className='search-input-product'
                placeholder='Search for...'
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <Pagination
              currentFirstNo={truncateNum(
                state.products.length ? pageOffset + 1 : 0,
                0,
                state.products.length
              )}
              currentLastNo={truncateNum(
                pageOffset + pageLimit,
                0,
                state.products.length
              )}
              totalNo={state.products.length}
              onPreviousClick={(e) => {
                e.stopPropagation();
                setPageOffset((o) => truncateNum(o - pageLimit, 0, o));
              }}
              onNextClick={(e) => {
                e.stopPropagation();
                setPageOffset((o) => {
                  const newOffset = truncateNum(
                    o + pageLimit,
                    0,
                    state.products.length
                  );
                  console.log(
                    'New oF',
                    newOffset === state.products.length ? o : newOffset
                  );
                  return newOffset === state.products.length ? o : newOffset;
                });
              }}
            />
          </div>
          <table className='__table __h-50 __product'>
            <thead className='__sticky'>
              <tr>
                <th scope='col'>S/N</th>
                <th scope='col'>Product &amp; Description</th>
                <th scope='col'>{'Price ($)'}</th>
                <th scope='col'>Popularity</th>
                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {/* {state.orders.length > 0 ? ( */}
              {loading ? <div className="spinner"></div>: state.products.length > 0 ? (
                <>
                  {/* {state.orders.map((order, index) => { */}
                  {filterProduct(
                    getFromOffsetToLimit(
                      sortProduct(state, sortType, isAscend),
                      pageOffset,
                      pageLimit
                    ),
                    searchValue
                  ).map((product, index) => {
                    const actions = (
                      <Actions
                        onEditClick={(e) => {
                          e.stopPropagation();
                          const lastEdit = new Date(
                            product.last_edit
                          ).getTime();
                          const nextEdit =
                            adminEditMinutes * 60 -
                            (new Date().getTime() - lastEdit) / 1000;
                          nextEdit > 0
                            ? Swal.fire(
                                '',
                                `You have ${Math.floor(
                                  nextEdit / 60
                                )} minutes until next edit for this product`,
                                'warning'
                              )
                            : navigate('/edit-product/' + product.sno);
                        }}
                        // onPriceChangeClick={(e) => {
                        //   e.stopPropagation();
                        //   setIsModalVisible({
                        //     product: {
                        //       price: product?.price,
                        //       sno: product?.sno,
                        //     },
                        //     modal: true,
                        //   });
                        // }}
                        onDeleteClick={(e) => {
                          e.stopPropagation();
                          deleteProduct(product.sno);
                        }}
                      />
                    );
                    return (
                      <tr
                        key={product.sno}
                        onClick={() => navigate('/products/' + +product.sno)}
                      >
                        {actions}
                        <th data-title='S/N' scope='row'>
                          <span className='__line-clamp-3'>
                            {' '}
                            {pageOffset + index + 1}
                          </span>
                        </th>
                        <td data-title='Product & Description'>
                          <ProductDetail product={product} />
                        </td>
                        <td
                          data-title='Price ($)'
                          className='text-right'
                          onClick={(e) => {
                            e.stopPropagation();
                            const lastEdit = new Date(
                              product.last_edit
                            ).getTime();
                            const nextEdit =
                              adminEditMinutes * 60 -
                              (new Date().getTime() - lastEdit) / 1000;
                            nextEdit > 0
                              ? Swal.fire(
                                  '',
                                  `You have ${Math.floor(
                                    nextEdit / 60
                                  )} minutes until next edit for this product`,
                                  'warning'
                                )
                              : setIsModalVisible({
                                  product: {
                                    price: product?.price,
                                    sno: product?.sno,
                                  },
                                  modal: true,
                                });
                          }}
                        >
                          {`${product.price}.00`}
                        </td>
                        <td data-title='Popularity' className='text-right'>
                          {getPopularity(state, product)}
                        </td>
                        <td data-title='Actions'>{actions}</td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr className='text-center container mx-auto'>
                  <td colSpan={5}>
                    <div className='mt-4'>
                      <EmptyResult
                        to='/addproduct'
                        desc='You have not added any product yet.'
                        height={100}
                        action='Add Product'
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </PageLayout>
    </>
  );
};

export default ProductList;
