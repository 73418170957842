import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const Pagination = ({
  currentFirstNo,
  currentLastNo,
  totalNo,
  onPreviousClick,
  onNextClick,
}) => {
  return (
    <div className='__pagination'>
      <LeftOutlined onClick={onPreviousClick} />
      {`${currentFirstNo}-${currentLastNo} of ${totalNo}`}
      <RightOutlined onClick={onNextClick} />
    </div>
  )
}

export default Pagination
