import React from 'react';
import { Popconfirm, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import {
  Layout,
  Menu,
  Image,
  Modal,
  Input,
  Badge,
  Avatar,
  Dropdown,
} from 'antd';
import { InputLabel } from '../inputFields/Input';
import { PrimaryBtn } from '../button/Button';

const Actions = ({ onEditClick, onDeleteClick, onPriceChangeClick }) => {
  return (
    <div className='__live__btn_container'>
      {onPriceChangeClick&&
      <Button className='__order-btn-primary' onClick={onPriceChangeClick}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z'
          />
        </svg>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          stroke-width='1.5'
          stroke='currentColor'
          class='w-6 h-6'
        >
          <path
            stroke-linecap='round'
            stroke-linejoin='round'
            d='M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z'
          />
        </svg>
      </Button>}
      <Button className='__order-btn-primary' onClick={onEditClick}>
        Edit
      </Button>
      {onDeleteClick && (
        <Popconfirm
          title='Are you sure you want to permanently delete this product?'
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          // onConfirm={() => this.delete(product.sno)}
          onConfirm={onDeleteClick}
          okText='Yes'
          cancelText='No'
        >
          <Button
            className='__order-btn-danger'
            onClick={(e) => e.stopPropagation()}
          >
            Delete
          </Button>
        </Popconfirm>
      )}
    </div>
  );
};

export default Actions;

// Price Change Modal Modal
export function PriceChangeModal({
  isModalVisible,
  handleOk,
  price,
  handleCancel,
  handleChangePrice,
  setSetPrice,
  setPrice,
  handleSubmit,
}) {
  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      title='Update Product price'
      zIndex={9999}
    >
      <div className=''>
        {/* <form onSubmit={handleSubmit} > */}
        <div className=' form-group'>
          <InputLabel
            label='Price'
            name='price'
            value={setPrice}
            onChange={(e) => setSetPrice(e.target.value)}
            placeholder='Product price'
            require
          />
          <PrimaryBtn
            text='Update'
            className={'mt-4 text-white bg-primary'}
            block
            type='submit'
            onClick={handleChangePrice}
          />
        </div>
        {/* </form> */}
      </div>
    </Modal>
  );
}
