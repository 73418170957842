import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Image } from "antd";
import http from "axios";

import { MerchantService } from "../api";
import { AdminService } from "../api";
import logo from "../assets/images/logo.png";
import illus from "../assets/images/auth.svg";
import {
  InputLabel,
  SelectLabel,
  PasswordInput,
} from "../components/inputFields/Input";
import { PrimaryBtn } from "../components/button/Button";
import { obj } from "../constants";

const ADMIN_ROOT = obj.adminurl+"/apiLogin.php";

class NewLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loginType: "",
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    const data = { username: this.state.email, password: this.state.password, loginType: this.state.loginType };
    if (data.loginType === "Login as Super Admin") {
      e.preventDefault();
      const username = btoa(data.username);
      const password = btoa(data.password);
      AdminService.login(username, password)
        .then((res) => {
          if (res.error) {
            Swal.fire("Sorry", res.message, "error");
          } else {
            const url = ADMIN_ROOT+`?usertype=superadmin&username=${username}&password=${password}`
            window.location.href = url;
          }
        })
        .catch((err) => {
          Swal.fire("Sorry", err.message, "error");
        });
      
    } else {
      e.preventDefault();
      MerchantService.login(data)
        .then((res) => {
          // console.log("LOGIN RES", res)
          if (res.success) {
            Swal.fire("Success", "Logged in", "success");
            sessionStorage.setItem("merchantid", res.user.merchant_id);
            sessionStorage.setItem("merchantemail", res.user.email);
            // sessionStorage.setItem("merchantemail", res.user.loginType);
            setTimeout(() => {
              window.location = "/products";
            }, 2000);
          } else {
            Swal.fire("Sorry", res.message, "error");
          }
        })
        .catch((err) => {
          Swal.fire("Sorry", err.message, "error");
        });
    }
  };

  render() {
    return (
      <div className="__auth_container pb-5">
        <div className="container mx-auto __auth_content">
          <div className=" __grid">
            <div className=" d-none d-lg-block px-0  text-center">
              <div className="__info_img ">
                <Image src={illus} preview={false} alt="login" width={500} />
              </div>
            </div>
            <div className="w-100 px-0">
              <div className="__info_content ">
                <div className="__info_con">
                  <div className="d-flex align-items-center mb-3">
                    <Image
                      src={logo}
                      alt="EAMR Logo"
                      width={50}
                      preview={false}
                    />
                    <h3>EAMR</h3>
                  </div>
                  <h4>Welcome Back!</h4>
                  <p>Please enter your details</p>
                </div>
                <form className="" onSubmit={this.handleSubmit} action={ADMIN_ROOT}>
                  {/* Login Type */}
                  <div className=" form-group py-4">
                    {/* <SelectLabel
                      label="Select Login Type"
                      choices={["Login as Merchant", "Login as Super Admin"]}
                      name="loginType"
                      // defaultValue="--select login type--"
                      value={this.state.loginType}
                      onChange={this.handleChange}
                    /> */}
                  </div>
                  {
                    // this.state.loginType === "Login as Super Admin" ?
                    // <p className="text-center">
                    //   <a href="http://54.179.23.180/exhibition/" className="btn btn-primary">Go to super admin panel</a>
                    // </p>
                    
                    // :
                  <div>
                    
                  {/* Email address */}
                  <div className=" form-group mb-3">
                    <InputLabel
                      type="email"
                      label="Email address"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder="`e.g johndoe908@domain.com`"
                      require
                    />
                  </div>
                  {/* Password */}
                  <div className=" form-group mb-3">
                    <PasswordInput
                      label="Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      placeholder="`**********`"
                      require
                    />
                  </div>
                  <div className="form-group clearfix">
                    <Link to="/forgot-password" className="__link">
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="form-group">
                    <PrimaryBtn
                      block
                      text="Log in"
                      onClick={this.handleSubmit}
                    />
                  </div>
                  </div>

                  }
                </form>
                <div className="text-center pb-3">
                  <span> Don't have an account? </span>
                  <Link className="font-weight-bold" to="/signup">
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewLogin;
