import { Button } from "antd";

// Primary Button
export const PrimaryBtn = ({ text, children, className, onClick, ...rest }) => (
  <>
    <Button
      type="primary"
      className={`__btn ${className}`}
      size="large"
      onClick={onClick}
      {...rest}
    >
      {text ? text : children}
    </Button>
  </>
);

// Secondary Button
export const SecBtn = ({ text, children, className, onClick, ...rest }) => (
  <>
    <Button
      className={`__btn ${className}`}
      size="large"
      style={{ borderColor: "#1890ff", color: "#1890ff" }}
      onClick={onClick}
      {...rest}
    >
      {text ? text : children}
    </Button>
  </>
);


// Danger Fill Button
export const DangerBtn = ({
  text,
  children,
  className,
  type,
  onClick,
  ...rest
}) => (
  <>
    <Button
      type={type}
      danger
      className={`__btn ${className}`}
      size="large"
      onClick={onClick}
      {...rest}
    >
      {text ? text : children}
    </Button>
  </>
);
