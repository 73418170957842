import React from 'react'
import { Menu } from 'antd'

const SortingMenu = ({ handleClick }) => {
  return (
    <Menu style={{ padding: '0 8px'}}>
      <Menu.Item key="1" onClick={() => handleClick('Name')}>
        Name
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleClick('Price')}>
        Price
      </Menu.Item>{' '}
      <Menu.Item key="3" onClick={() => handleClick('Popularity')}>
        Popularity
      </Menu.Item>
    </Menu>
  )
}

export default SortingMenu
