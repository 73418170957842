import React, { Component } from "react";
import {LeftSideBar} from "../layouts";
import { Header } from "../layouts";
import { Link } from "react-router-dom";


import productImg from "../../assets/images/product-img1.png";
import starIcon from "../../assets/images/star-yellow-ico.svg";

class Chat extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    componentDidMount() {
        console.log(this.props.lastMsg)
    }
    

    render() {
        return (
            <article style={{cursor: "pointer"}} className="d-flex chat__container" onClick={this.props.displayChat}>
                <img src={this.props.avatar} alt="" className="review-avatar rounded-circle" />
                <div>
                    <div className="d-flex justify-content-between">
                        <p className="font-weight-bold">{this.props.group.name} ({this.props.group.product_name})</p>
                        <p className="text-muted small">{new Date(this.props.group.created_date).toDateString()}</p>
                    </div>
                    <div className="small">{this.props.lastMsg ? this.props.lastMsg.message.slice(0, 50) : ""}</div>
                </div>
            </article>
        )
    }
}

export default Chat;

