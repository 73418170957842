import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Space } from 'antd';

import avatarIcon from '../../assets/images/avatar.png';
import { ProductService } from '../../api';
import PageLayout from '../layouts/pageLayouts';
import defaultImg from '../../assets/images/default.png';
import SingleReview from './singleReview';
import leftArrowIcon from '../../assets/images/arrow-left.svg';

const { Title, Text, Paragraph } = Typography;
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productImages: ['../../assets/images/product-img1.png'],
      product: {},
      nextEdit: 0,
      adminEditMinutes: 30,
      reviews: [],
      adminEditTime: null,
    };
  }

  getid = () => {};
  componentDidMount() {
    const email = { email: sessionStorage.getItem('merchantemail') };
    const id = window.location.pathname.split('/')[2];
    console.log(id);

    ProductService.productedittime(email).then((res) => {
      const result = res.result[0];
      const timeSplit = result?.product_edit_time;
      const time = timeSplit.split(':');
      const minutes = +time[0] * 60 + +time[1];
      console.log(res.result[0], minutes, 'edittt');
      // this.setState({ adminEditMinutes: Number(minutes) });
      const mins =Number(minutes);
      const lastEdit = new Date(this.state.product.last_edit).getTime();
      const nextEdit =
      mins * 60 -
        (new Date().getTime() - lastEdit) / 1000;
      this.setState({
        nextEdit: Math.ceil(nextEdit) > 0 ? Math.ceil(nextEdit) : 0,
      });
      setInterval(() => {
        if (this.state.nextEdit > 0) {
          this.setState({ nextEdit: this.state.nextEdit - 1 });
        }
      }, 1000);
    });

    ProductService.fetchproduct(id).then((res) => {
      this.setState({ product: res.data[0] });
    });
    ProductService.fetchreviews({ product_id: id }).then((res) => {
      this.setState({ reviews: res.result });
      console.log(res);
    });

  
  }
  formatTime = (time) => (time > 9 ? time : '0' + time);

  // timeSplit() {
  //   const lastEdit = new Date(this.state.product.last_edit).getTime();
  //   const nextEdit =
  //     this.state.adminEditMinutes * 60 -
  //     (new Date().getTime() - lastEdit) / 1000;
  //   console.log(this.state.adminEditMinutes, 'admin');
  //   return nextEdit / 60;
  // }

  render() {
    console.log(this.state.adminEditMinutes,'lll');
    return (
      <>
        <PageLayout>
          <div className='row'>
            <div className='col-12 mb-3'>
              <Link to='/products' className='p-2 rounded border bg-white'>
                <img src={leftArrowIcon} alt='Go back' />
              </Link>
            </div>
            <div className='col-md-3'>
              <div className='__pdt_img'>
                <div className='img-encl'>
                  <div className='img-wrap __pdt_imgwrap'>
                    <img
                      className='img-fluid border rounded'
                      src={this.state.product.photo1 || defaultImg}
                      alt='img'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-9'>
              <div className='__pdt_info'>
                <div className='d-flex justify-content-between align-items-start product__title border-bottom'>
                  <div className='pb-2'>
                    {/* Product name */}
                    <div className='d-flex items-center'>
                      <Title level={4}>{this.state.product.name}</Title>{' '}
                    </div>
                    {/* Product description */}
                    <Paragraph>{this.state.product.description}</Paragraph>
                    {this.formatTime(Math.floor(this.state.nextEdit / 60)) ===
                      '00' &&
                      this.formatTime(this.state.nextEdit % 60) === '00' && (
                        <Link
                          className='edit_button'
                          to={`/edit-product/${this.state.product.sno}`}
                        >
                          Edit product
                        </Link>
                      )}
                  </div>
                  <div className='col-5 text-right'>
                    <p>
                      <strong>{this.state.product.popularity} persons</strong>
                    </p>
                    <p className='small'>Interested in this product</p>
                  </div>
                </div>
                <div className='product__price-container d-flex align-items-center mb-3'>
                  {/* Product price */}
                  <Title level={3} className='mb-0'>
                    ${this.state.product.price}
                  </Title>
                  <Text type='secondary' className='ml-3' strong>
                    {this.formatTime(Math.floor(this.state.nextEdit / 60)) +
                      'min'}
                    :{this.formatTime(this.state.nextEdit % 60) + 'sec '}
                    {/* 00:00:00  */}
                    until next edit
                  </Text>
                </div>
                <Space size={60}>
                  {/* Serial number */}
                  <div className=''>
                    <p className='font-weight-bold'>Serial number</p>
                    <Text strong>{this.state.product.sno}</Text>
                  </div>
                  {/* Size */}
                  <div className=''>
                    <p className='font-weight-bold'>Size</p>
                    <Text strong>{this.state.product.dimension}</Text>
                  </div>
                  {/* Stock */}
                  <div className=''>
                    <p className='font-weight-bold'>Stock</p>
                    <Text strong>{this.state.product.stock}</Text>
                  </div>
                </Space>
              </div>
            </div>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <Title level={4} className='mt-3'>
                    Reviews
                  </Title>
                </div>
                {this.state.reviews.map((review) => (
                  <SingleReview
                    avatar={avatarIcon}
                    customerName={review.name}
                    comment={review.reviewdesc}
                    rating={review.review_rating}
                  />
                ))}
              </div>
            </div>
          </div>
        </PageLayout>
      </>
    );
  }
}

export default Product;
