import React, { useState } from 'react';
import Swal from 'sweetalert2';

import leftArrowIcon from '../../assets/images/arrow-left.svg';
import { Link } from 'react-router-dom';
import { ProductService } from '../../api';
import Layout from '../layouts/pageLayouts';
import { InputLabel } from '../inputFields/Input';
import { PrimaryBtn } from '../button/Button';
import ImgPreview from './UploadImageInput/ImgPreview';
import { message } from 'antd';
// import { obj } from '../../constants';
// const photoKeys = ['photo1', 'photo2', 'photo3', 'photo4'];

function AddMultiProduct() {
  const [file, setFile] = useState();
  const [filex, setFilex] = useState();
  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();
    if (file) {
      fileReader.onload = function (event) {
        form.append('file', file);
        ProductService.addproductviacsv(form).then((res) => {
          if (res.success) {
            Swal.fire('Success', res.message, 'success');
            setTimeout(() => {
              window.location = '/products';
            }, 2000);
          } else {
            Swal.fire('Error', res.msg, 'error');
          }
        }).catch((error)=>{
          message.error("csv upload failed");
        })
      };
      const x = fileReader.readAsText(file);
      setFilex(x);
    }
  };

  console.log(file, filex);

  return (
    <>
      <Layout>
        <div className='col-12'>
          <h4 className='d-flex align-items-center'>
            <Link to='/products' className='mr-3'>
              <img src={leftArrowIcon} alt='Go back' />
            </Link>
            <span className='__title'>Upload products file</span>
          </h4>
        </div>
        <div className='product__form-container col-12'>
          <form className='product__form' onSubmit={handleOnSubmit}>
            <div className='form-group pb-2'>
              <InputLabel
                type={'file'}
                id={'csvFileInput'}
                accept={'.csv'}
                label={'CSV File'}
                onChange={handleOnChange}
                require
              />
            </div>

            <PrimaryBtn
              text='Upload CSV File'
              block
              disabled={!file}
              onClick={(e) => {
                handleOnSubmit(e);
              }}
            />
          </form>
        </div>
        <UploadImages />
      </Layout>
    </>
  );
}

export default AddMultiProduct;

function UploadImages() {
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile([...file, e.target.files[0]]);
  };

  const handleOnSubmit = (e) => {

    e.preventDefault();
    setLoading(true);
    console.log('1');
    const form = new FormData();
    if (true) {
      fileReader.onload = function (event) {

       
        file.map((item, i) => form.append(`photo${i + 1}`, item));
        // let i =0;
        // let arr=[]
        //  for (i = 0; i < file.length; i++) {
        //   arr.push(form.append(`photo+${i+1}`, file[i]))
        //  }

        ProductService.addcsvimage(form).then((res) => {
          if (res.success) {
            Swal.fire('Success', res.message, 'success');

            setLoading(false);
            setTimeout(() => {
              window.location = '/products';
            }, 2000);
          } else {
            Swal.fire('Error', res.msg, 'error');
           
            setLoading(false);
          }
        }).catch((error)=>{
          message.error("Image upload failed");
          setLoading(false);
        })
      }

      fileReader.readAsText(file[0]);
   
      console.log(form, 'pppp');
    }
  };

  console.log(file, 'filex');
const removePhoto= (id) => {
setFile(file=>file.filter(el=> el.name !== id))
}
  return (
    <>
      <div className='col-12'></div>
      <div className='product__form-container col-12'>
        <form className='product__form'>
          <div className='form-group pb-2'>
            <InputLabel
              type={'file'}
              id={'imagesUpload'}
              accept={'image/jpeg, image/png, image/gif, image/svg, image/*'}
              label={'Multiple images'}
              onChange={handleOnChange}
              require
            />
          </div>

          <div className='uii__img_preview-container my-4'>
            {file.map((item) => (
              <div key={item}>
                <ImgPreview
                  photo={item}
                  onDeleteClick={() => {
                    removePhoto(item.name)
                  }}
                />
                <p className='progress-label'>{item.name}</p>
              </div>
            ))}
          </div>

          {file?.length > 0 && (
            <PrimaryBtn
              text='Submit'
              block
              disabled={loading }
              onClick={(e) => {
                handleOnSubmit(e);
              }}
            />
          )}
        </form>
      </div>
    </>
  );
}
