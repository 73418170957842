import React from "react";
import { Tooltip, Image } from "antd";
import defaultImg from "../../assets/images/default.png";
import truncate from "truncate";

const ProductDetail = ({product}) => {
  return (
    <div className="d-flex border-0 align-items-center">
      <div className="">
        <Image
          src={product.photo1 || defaultImg}
          preview={false}
          alt="product"
          width={60}
          style={{
            borderRadius: "2px",
            border: "1px solid #eee",
          }}
        />
      </div>
      <div className="__item_x ml-3">
        <Tooltip title={product.name} color="#1890ff" placement="top">
          <h6 className="mb-2 text-left">{truncate(product.name, 50)}</h6>
        </Tooltip>
        <Tooltip title={product.description} color="#1890ff" placement="bottom">
          <p className="text-left p-0">{truncate(product.description, 80)}</p>
        </Tooltip>
      </div>
    </div>
  );
};

export default ProductDetail;
