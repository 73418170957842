import React from 'react'
import { Menu } from 'antd';

const SortingMenu = ({handleClick}) => {
  return (
    <Menu style={{padding: "0 8px"}}>
    <Menu.Item key="1" onClick={() => handleClick('ID')}>
      ID
    </Menu.Item>
    <Menu.Item key="2" onClick={() => handleClick('Product')}>
      Product
    </Menu.Item>
    <Menu.Item key="3" onClick={() => handleClick('Customer')}>
      Customer
    </Menu.Item>
    <Menu.Item key="4" onClick={() => handleClick('Date')}>
      Date
    </Menu.Item>
  </Menu>
  )
}

export default SortingMenu