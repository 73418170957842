import React, { Component } from "react";
import { Row, Col, Badge, Layout } from "antd";
import { Link } from "react-router-dom";

import burgerIcon from "../../assets/images/burger-ico.svg";
import avatarIcon from "../../assets/images/avatar.png";
import adminAvatar from "../../images/admin.png";
import Avatar from "../../images/admin.png";
import notification_ico from "../../assets/images/notification-ico.svg";
import search_dark_ico from "../../assets/images/search-dark-ico.svg";
import "../../App.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: "",
    };
  }

  render() {
    return (
      <header
        className="p-2 mb-3 border-bottom d-flex justify-content-between align-items-center"
        style={{ backgroundColor: "rgba(237, 245, 249, 1)" }}
      >
        <div
          className="d-flex col-2 justify-content-around"
          style={{ marginLeft: "200px" }}
        >
          <a
            href="#"
            className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none"
          >
            <img src={burgerIcon} />
          </a>
          <h3>Merchant Panel</h3>
        </div>
        <div className="d-flex justify-content-end col-5 subheader">
          <div>
            <input type="search" style={{ visibility: "hidden" }} />
            <img src={search_dark_ico} alt="Search" />
          </div>
          <Link to="/notifications">
            <img
              className="img-fluid"
              src={notification_ico}
              alt="Notification"
            />
          </Link>
          <div className="dropdown text-end">
            <a
              href="#"
              className="d-block link-dark text-decoration-none dropdown-toggle"
              id="dropdownUser1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={avatarIcon}
                alt="mdo"
                width="32"
                height="32"
                className="rounded-circle"
              />
            </a>
            <ul
              className="dropdown-menu text-small"
              id="dropdown"
              aria-labelledby="dropdownUser1"
            >
              <li>
                <a className="dropdown-item" href="#">
                  New project...
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Settings
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Profile
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Sign out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
