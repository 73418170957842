import { Result } from "antd";
import { Link } from "react-router-dom";
import PageLayout from "../layouts/pageLayouts";
import {PrimaryBtn} from '../button/Button'

export default function Error404() {
  return (
    <PageLayout>
      <div className="container mx-auto">
        <div className="text-center">
          <Result
            status="404"
            title="404"
            subTitle="Oops! Page not found."
            extra={
              <Link
                to="/products"
                className=""
              >
                <PrimaryBtn text="Go Back" />
              </Link>
            }
          />
        </div>
      </div>
    </PageLayout>
  );
}
