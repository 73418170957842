import React, { useEffect, useRef, useState } from "react";
import "./style.css";

const ImgPreview = ({ photo, onDeleteClick }) => {
  return (
    <>
    {photo ?  <div className="uii__preview_img">
          <button type="button" onClick={(e) => {
            e.preventDefault()
            onDeleteClick(photo)
          }}>&#x274C;</button>
          <img src={photo.constructor === File ? URL.createObjectURL(photo) : photo} alt={"img"} />
        </div> : null}
   
    </>
  );
};

export default ImgPreview;
